/* header 06 */
@media screen and (min-width: $mobile-menu-breikpoint) {
  .hdr-style-6 {
    border-bottom: 0;
    .logo-holder-s {
      @include flexWidth(250px);
    }
    .logo-holder-s {
      @include flexWidth(200px);
    }
    .minicart-qty {
      display: block;
      position: absolute;
      left: 18px;
      top: -1px;
      width: 21px;
      height: 21px;
      margin: 0;
      font-size: 12px;
      line-height: 21px;
      color: #fff;
      text-align: center;
      background-color: $custom_color;
      border-radius: 50%;
    }
    .search {
      padding: 0;
    }
    .search-input[type] {
      width: calc(100% - 85px);
    }
    .nav-holder {
      margin: 0;
      .hdr-nav {
        margin: 0;
        min-height: 60px;
        border-bottom: 0;
        background-color: $desktop-menu-color_bg;
      }
    }
    .hdr-desktop .mmenu:not(.mmenu--vertical) > li > a {
      color: $desktop-menu-color_color;
      &:hover {
        color: $desktop-menu-color-hover_color;
      }
      &:before {
        background-color: $desktop-menu-color_line_color;
      }
    }
    .is-hovered .minicart-link > [class*='icon']:before {
      font-size: 1em;
      margin-left: 0;
    }
  }
}

.hdr-style-6 {
  .dropdn_settings .dropdn-link > .icon {
    font-size: 22px;
  }
  .dropdn_settings .dropdn-content .container,
  .mobilemenu-settings .dropdn-content .container {
    display: flex;
    @include justify-content(space-between);
    ul li span + span {
      display: none;
    }
    .vert-divider {
      width: 1px;
      background-color: $header_border_color;
    }
  }
}

@media screen and (max-width: ($mobile-menu-breikpoint - 1)) {
  .hdr-style-6 {
    .mobilemenu-settings .dropdn-content .container {
      flex-direction: column;
      .vert-divider {
        display: none;
      }
    }
    .mobilemenu-settings {
      display: inline-block;
      position: static;
      .dropdn-link .icon {
        font-size: 23px;
      }
    }
  }
}