.popup-container{
    background-color: $white;
    padding: 30px 40px 35px 40px;
    text-align: left;
    margin: 40px auto;
    position: relative;
    &.small{
        max-width: 600px;
    }
    &.medium{
        max-width: 800px;
    }
    &.large{
        max-width: 1000px;
    }

}

@media only screen and (max-width: 767px){
    .popup-container{
        padding: 15px 20px 15px 20px;
        .column,
        .columns{
            width:100% !important;
        }
    }
}