.faq-item {
  .panel {
    margin-bottom: 0;
  }
  .panel > .panel-heading {
    margin-left: 28px;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    font-family: $heading_font_family;
    font-weight: 500;
    border-width: 0 0 1px 0;
    @media (max-width: $screen-sm-max) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &:last-child .panel > .panel-heading {
    border: 0;
  }
  .panel-body {
    padding: 20px 0 20px 28px;
    @media (max-width: $screen-sm-max) {
      padding: 10px 0 10px 28px;
    }
  }
  .panel-heading a {
    padding: 24px 0;
    @media (max-width: $screen-sm-max) {
      padding: 14px 0;
    }
  }
  .panel-heading a, .panel-heading a:focus {
    color: $faq-panel-heading-link_color;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .panel-heading:hover a, .panel-heading:hover:after {
    color: $faq-panel-heading-link-active_color;
  }
  .panel > .panel-heading.active:after, .panel > .panel-heading.active a {
    color: $faq-panel-heading-link-active_color;
  }
  .panel > .panel-heading:after {
    top: 50%;
    left: -28px;
    right: auto;
    margin-top: -17px;
    padding-left: 0;
    width: auto;
    height: 30px;
    content: '+';
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    @include transition(all 0s, color .2s);
  }
  .panel > .panel-heading:before {
    display: none;
  }
  .panel > .panel-heading.active:after {
    content: '\2013';
    margin-top: -21px;
    @include transform(rotate(0deg));
  }
}

.simple-filter-tabs {
  @include flexbox();
  flex-wrap: wrap;
  @media (max-width: $screen-sm-max) {
    @include justify-content(center);
    margin-bottom: 5px;
  }
  & > span {
    font-size: 14px;
    line-height: 36px;
    font-weight: 600;
    font-family: $heading_font_family;
    text-transform: uppercase;
    margin: 0 10px;
    cursor: pointer;
    color: $simple-filter-link_color;
    white-space: nowrap;
    @media (max-width: $screen-sm-max) {
      line-height: 30px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include transition(color .2s);
    &:hover,
    &:focus,
    &.active {
      color: $simple-filter-link-active_color;
    }
    span {
      padding-left: 10px;
      color: $simple-filter-text_color;
      font-weight: 400;
    }
  }
}

.text-center > .simple-filter-tabs {
  @include justify-content(center);
}

.simple-filter .h1-style {
  margin-bottom: 20px;
}

.simple-filter .faq-item {
  opacity: 0;
  @include transition(.2s);
  &.isvisible {
    opacity: 1;
  }
}

body.android .faq-item .panel > .panel-heading.active:after {
  margin-top: -18px;
}