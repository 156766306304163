.breadcrumbs {
  padding: 23px 0;
  list-style: none;
  color: $breadcrumbs_color;
  @media screen and (max-width: $screen-sm-max) {
    padding: 15px 0;
  }
  li {
    display: inline-block;
    padding: 0 10px 0 0;
    font-size: 14px;
    line-height: 20px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      white-space: nowrap;
      color: $breadcrumbs_color;
      &:hover {
        color: $breadcrumbs-hover_color;
      }
    }
    &:after {
      position: relative;
      margin-right: -8px;
      padding-left: 8px;
      color: $text_color_dark;
      content: "\e919";
      vertical-align: bottom;
      font-size: 8px;
      font-family: 'icomoon';
      line-height: 20px;
    }
    &:last-child:after {
      display: none;
    }
  }
}