.product-filters {
  .filter-price {
    .slide-tooltip {
      position: absolute;
      z-index: 5000;
      background-color: #fff;
      color: $holder_bg-1;
      border: 1px solid $holder_bg-1;
      padding: 3px 5px;
      top: -40px;
      &.min-slider-tooltip {
        z-index: 5001;
      }
      &.max-slider-tooltip {
        right: 0;
      }
    }
    .slider {
      &.slider-horizontal {
        width: calc(100% - 20px);
        margin-top: 40px;
        .slider-track {
          left: 10px;
        }
      }
    }
    .price-input {
      input {
        width: 100%;
      }
    }
    .between-price {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  .form-check {
    padding-left: 0;
  }
  .filter-manufacturer {
    .select2-selection {
      border-radius: 0 !important;
      border: 1px solid #e2e2e2 !important;
      box-shadow: none !important;
      color: #656565 !important;
    }
    .select2-search__field {
      height: 40px !important;
    }
  }
}

.filter-manufacturer-dropdown {
  z-index: 10001;
}