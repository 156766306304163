.brand-prd-carousel {
  margin: 30px 0;
  @include respond-to(sm-only) {
    margin: 25px 0;
  }
  @include respond-to(xs-only) {
    margin: 15px 0;
  }
  &:last-child {
    margin-bottom: 30px;
  }
  &:first-child {
    margin-top: 0;
  }
}

@media screen and (min-width: $screen-md-min) {
  .brand-prd-carousel {
    &:last-child {
      margin-bottom: 55px;
    }
  }
}

.brand-prd {
  @media screen and (min-width: $screen-md-min) {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    overflow: hidden;
  }
}

.brand-prd-image {
  @media screen and (min-width: $screen-md-min) {
    float: left;
    width: 50%;
  }
  @media screen and (max-width: $screen-md-max) {
    img {
      width: 100%;
    }
  }
}

.brand-prd-info {
  @media screen and (min-width: $screen-md-min) {
    @include flexbox();
    width: 50%;
  }
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 20px;
  }
  @media screen and (max-width: $screen-xs-max) {
    margin-top: 10px;
  }
  [class*='btn'] {
    margin-top: 15px;
  }
  align-content: center;
  justify-content: center;
  flex-direction: column;
  float: left;
  p {
    font-weight: 300;
  }
  .inside {
    padding: 20px 75px;
    @media screen and (max-width: $screen-lg-max) {
      padding: 15px 40px 15px 20px
    }
    @media screen and (max-width: $screen-sm-max) {
      padding: 15px 18px 15px
    }
    & > * {
      margin-top: 30px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 20px;
      }
    }
    & > *:first-child {
      margin-top: 0;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    img {
      max-width: 50%;
    }
  }
}

@media screen and (max-width: $screen-sm-max) {
  .brand-prd-carousel.vert-dots .slick-dots {
    top: auto;
    bottom: -20px;
    left: auto;
    right: auto;
    width: 100%;
    @include flex-direction(row);
    @include justify-content(center);
    li {
      margin: 0 8px;
    }
  }
}

.aside .brand-prd-info {
  .inside {
    padding: 10px 30px;
    @media screen and (max-width: $screen-lg-max) {
      padding: 0 30px;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding: 0 30px;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding: 0 30px;
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .aside .brand-prd-carousel.vert-dots .slick-dots {
    right: 16px;
  }
}