$light-grey: #e9e9e9;

.address-box{
    margin-bottom: 20px;
    border: 1px solid $light-grey;
    .address-details-box{
        padding: 20px;
        min-height: 250px;
        ul {
            padding: 0;
            li {
                padding: 1px 0px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .address-control-box{
        padding: 10px 20px;
        border-top: 1px dashed $light-grey;
        line-height: 18px;
        a{
            display: inline-block;
        }
    }

}