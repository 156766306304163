/* BnSlider */

.bnslider-wrapper {
  position: relative;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
}

.bnslider {
  overflow: hidden;
  height: 325px;
  margin-bottom: 0;
  background: $slide_bg;
  .animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
  }
  .w-70 {
    width: 70% !important;
  }
  .w-33 {
    width: 33.333% !important;
  }
}

.bnslider > * {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
}

.bnslider.slick-initialized > * {
  opacity: 1;
  visibility: visible;
}

.bnslider-link {
  &, &:hover, &:focus, &:active {
    color: inherit !important;
  }
}

.bnslider.bnslider--fullheight {
  max-height: 100vh;
  min-height: 100vh;
}

@media only screen and (min-width: $screen-md-min) {
  .bnslider {
    height: 475px;
    min-height: 475px;
  }
  .bnslider.bnslider--md {
    height: 594px;
    min-height: 594px;
    max-height: calc(100vh - 85px);
  }
  .bnslider.bnslider--lg {
    height: 768px;
    min-height: 768px;
    max-height: calc(100vh - 85px);
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .bnslider {
    height: 375px;
    min-height: 375px;
    max-height: calc(100vh - 85px);
  }
  .bnslider.bnslider--md {
    height: 450px;
    min-height: 450px;
    max-height: calc(100vh - 85px);
  }
  .bnslider.bnslider--lg {
    height: 575px;
    min-height: 575px;
    max-height: calc(100vh - 85px);
  }
}

.bnslider .bnslider-slide,
.bnslider .slick-list,
.bnslider .slick-track {
  height: 100%
}

.bnslider:hover {
  .slick-prev, .slick-next {
    opacity: 1;
  }
}

.video-is-playing .slick-dots {
  display: none !important
}

.bnslider-pause:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  color: #fff;
  background-color: #7796a8;
  padding: 27.5px;
  z-index: 10000;
  transition: none
}

.video-is-playing .bnslider-pause:focus {
  display: none
}

.bnslider-pause-stop {
  display: block
}

.is-paused .bnslider-pause-stop {
  display: none
}

.bnslider-pause-play {
  display: none
}

.is-paused .bnslider-pause-play {
  display: block
}

.bnslider-slide {
  position: relative;
  overflow: hidden
}

.bnslider-image-mobile {
  display: none;
}

@media screen and (max-width: $mobile-slider-breikpoint) {
  .bnslider-image-mobile {
    display: block;
  }
  .bnslider-image-mobile + .bnslider-image {
    display: none;
  }
}

.bnslider-image, .bnslider-image-mobile {
  transition: opacity 0.8s cubic-bezier(0.44, 0.13, 0.48, 0.87);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  &.bg-left {
    background-position: center left;
  }
  &.bg-right {
    background-position: center right;
  }
  &.bg-bottom {
    background-position: bottom center;
  }
  &.bg-top {
    background-position: top center;
  }
  &.bg-bottom.bg-left {
    background-position: bottom left;
  }
  &.bg-bottom.bg-right {
    background-position: bottom right;
  }
  &.bg-top.bg-left {
    background-position: top left;
  }
  &.bg-top.bg-right {
    background-position: top right;
  }
}

.slick-initialized .bnslider-image,
.slick-initialized .bnslider-image-mobile,
.no-js .bnslider-image {
  opacity: 1
}

.bnslider-slide--background-video .bnslider-image {
  opacity: 0
}

.no-autoplay .bnslider-image {
  opacity: 1
}

.ie9 .bnslider-slide {
  z-index: 1 !important
}

.ie9 .slick-dots {
  z-index: 2
}

.bnslider-text-wrap {
  height: 100%;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}

.bnslider-link .bnslider-text-wrap {
  cursor: inherit
}

.bnslider-link, .bnslider-link:hover {
  text-decoration: none
}

.bnslider-slide--has-background-video .bnslider-text-wrap {
  padding-top: 165px
}

.video-is-playing .bnslider-text-wrap {
  display: none
}

.bnslider-slide.video-is-paused .bnslider-text-wrap {
  display: none
}

.bnslider-text-content {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3
}

.slick-active .bnslider-text-content,
.no-js .bnslider-text-content {
  opacity: 1
}

.bnslider-slide--has-background-video::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2
}

.bnslider-video {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2
}

.bnslider-video--background {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;
  height: 100%;
}

.autoplay .bnslider-video--background.video-is-loaded {
  display: block;
  visibility: visible;
  opacity: 1
}

.bnslider-slide--background-video::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2
}

.bnslider-video--box {
  display: none;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in
}

.ie9 .bnslider-video--box {
  display: block
}

.bnslider-slide.video-is-playing .bnslider-video--box,
.bnslider-slide.video-is-paused .bnslider-video--box {
  display: block;
  visibility: visible;
  opacity: 1
}

.bnslider-video-control {
  display: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 5px;
  z-index: 4;
  transition: all 0.1s ease-out
}

.bnslider-video-control:hover,
.bnslider-video-control:focus {
  opacity: 0.7
}

.video-loader {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 4;
  color: $slide_nav_color;
  bottom: 100px;
  right: 35px;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.video-is-loaded .video-loader,
.video-is-playing .video-loader,
.video-is-paused .video-loader,
.autoplay .video-loader,
.no-autoplay .video-loader {
  content: none;
  display: none
}

.video-is-loading .video-loader,
.autoplay .video-is-loading .video-loader,
.no-autoplay .video-is-loading .video-loader {
  display: block;
  visibility: visible;
  opacity: 1
}

.bnslider-video-control--play .bnslider-video--background {
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.bnslider-video-control--close,
.bnslider-video-control--play {
  bottom: 80px;
  right: 15px;
  @media only screen and (max-width: $screen-md-max) {
    bottom: 50px;
  }
  @media only screen and (max-width: $screen-sm-max) {
    bottom: 40px;
  }
}

.video-is-loaded .bnslider-video-control--play,
.video-is-playing .bnslider-video-control--close,
.bnslider-slide.video-is-paused .bnslider-video-control--close {
  display: block;
  visibility: visible;
  opacity: 1;
  border: 0;
  background: transparent;
  width: 80px;
  height: 80px;
}

.video-is-loading .bnslider-video-control--play,
.video-is-playing .bnslider-video-control--play,
.bnslider-slide.video-is-paused .bnslider-video-control--play {
  display: none;
  visibility: hidden;
  opacity: 0
}

.bnslider-video-control--close .icon {
  display: block;
  width: 20px;
  height: 20px
}

svg.slider-control-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .box {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(255, 255, 255, 0);
    stroke-width: 3;
    stroke-linecap: square;
    transition: stroke 0s ease-out, fill .4s ease-out;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
    .box {
      stroke: rgba(255, 255, 255, 1);
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
      transition: stroke 0s ease-out, stroke-dashoffset 0s ease-out, fill .4s ease-out;
    }
  }
  .play {
    fill: rgba(255, 255, 255, .6);
    transition: all .6s ease-out;
  }
}

button:hover .slider-control-icon {
  .box {
    fill: $custom_color;
    stroke: rgba(255, 255, 255, 1);
    stroke-dashoffset: 0;
    transition: stroke .7s .4s ease-out, fill .4s .3s ease-out;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
    .box {
      stroke-dashoffset: 0;
      transition: stroke .7s .4s ease-out, stroke-dashoffset .4s ease-out, fill .4s .3s ease-out;
    }
  }
  .play {
    fill: rgba(255, 255, 255, 1);
  }
}

.bnslider-text-content {
  .bnslider-text-content-flex {
    padding: 15px 15px 30px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    text-align: center;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      padding: 5px 0 5px;
    }
    &.container {
      @media screen and (max-width: $screen-lg-max) {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media screen and (max-width: $screen-md-max) {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  &.txt-left .bnslider-text-content-flex {
    @include align-items(flex-start);
    text-align: left;
    .bnslider-text2-1,
    .bnslider-text2-2,
    .bnslider-text2-3 {
      float: left;
    }
  }
  &.txt-right .bnslider-text-content-flex {
    @include align-items(flex-end);
    text-align: right;
    .bnslider-text2-1,
    .bnslider-text2-2,
    .bnslider-text2-3 {
      float: right;
    }
  }
  &.txt-top .bnslider-text-content-flex {
    @include justify-content(flex-start);
  }
  &.txt-bottom .bnslider-text-content-flex {
    @include justify-content(flex-end);
  }
  .btn-slider {
    margin-left: auto;
    margin-right: auto;
  }
  &.txt-left .btn-slider {
    margin-right: auto;
    margin-left: 0;
  }
  &.txt-right .btn-slider {
    margin-left: auto;
    margin-right: 0;
  }
}

body.fullpage-layout .bnslider-text-content {
  .bnslider-text-content-flex {
    padding: 15px 30px 30px;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      padding: 5px 30px 5px;
    }
  }
}

.bnslider--fullheight .bnslider-text-content .bnslider-text-content-flex {
  @media screen and (max-width: $screen-md-max) {
    font-size: 55px;
  }
  @media screen and (max-width: $mobile-slider-breikpoint) {
    font-size: 47px;
  }
}

.bnslider-text-content .bnslider-text-content-flex {
  font-size: 100px;
  .bnslider-vert {
    @include flexbox();
    @include flex-direction(column);
    margin: 5% 8%;
    position: relative;
    @media screen and (max-width: $screen-md-max) {
      margin: 4% 5%;
    }
    @media screen and (max-width: $screen-sm-max) {
      margin: 3% 0;
      padding: 0 10px;
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      margin: 3% 0;
      padding: 0 10px;
      &.w-33 {
        width: 55% !important;
      }
      &.w-50 {
        width: 55% !important;
      }
      &.w-70 {
        width: 100% !important;
      }
    }
    & > *:not(:first-child):not(.clearfix):not(.btn-wrap) {
      margin-top: 20px;
      @media screen and (max-width: $screen-lg-max) {
        margin-top: 18px;
      }
      @media screen and (max-width: $screen-md-max) {
        margin-top: 15px;
      }
    }
    & > .btn-wrap {
      margin-top: 35px;
      @media screen and (max-width: $screen-lg-max) {
        margin-top: 20px;
      }
      @media screen and (max-width: $screen-md-max) {
        margin-top: 15px;
      }
    }
    & > .btn-wrap.double-mt {
      margin-top: 55px;
      @media screen and (max-width: $screen-lg-max) {
        margin-top: 45px;
      }
      @media screen and (max-width: $screen-md-max) {
        margin-top: 30px;
      }
    }
    &.border:after {
      content: '';
      @include fullAbsolute();
      border-width: .05em;
      border-style: solid;
      border-color: $slide-text-border_color;
      z-index: -1;
    }
    &.border-half:after {
      content: '';
      position: absolute;
      left: 20%;
      right: 20%;
      top: 0;
      bottom: 0;
      border-width: .05em;
      border-style: solid;
      border-color: $slide-text-border_color;
      z-index: -1;
      @media screen and (max-width: $screen-md-max) {
        left: 14%;
        right: 14%;
      }
      @media screen and (max-width: $mobile-slider-breikpoint) {
        left: 23%;
        right: 23%;
      }
    }
    &.border-double {
      @include justify-content(center);
    }
    &.border-double:after {
      content: '';
      position: absolute;
      left: -100%;
      right: -100%;
      top: 0;
      bottom: 0;
      border-width: .05em;
      border-style: solid;
      border-color: $slide-text-border_color;
      z-index: -1;
      @media screen and (max-width: $screen-md-max) {
        left: -80%;
        right: -80%;
      }
      @media screen and (max-width: $screen-sm-max) {
        left: -70%;
        right: -70%;
      }
      @media screen and (max-width: $mobile-slider-breikpoint) {
        left: 0;
        right: 0;
      }
    }
    &.border {
      padding: .65em .5em;
      @media screen and (max-width: $screen-md-max) {
        padding: .45em;
      }
      @media screen and (max-width: $mobile-slider-breikpoint) {
        padding: .35em;
      }
    }
    &.border-half {
      padding: .8em 0;
      @media screen and (max-width: $screen-md-max) {
        padding: .5em 0;
      }
      @media screen and (max-width: $mobile-slider-breikpoint) {
        padding: 15% 10%;
        min-height: 70%;
        margin-top: -10%;
        .bnslider-text--xxs {
          max-width: 65%;
        }
      }
    }
    &.border-double {
      padding: .8em .5em;
      @media screen and (max-width: $screen-md-max) {
        padding: .5em;
      }
      @media screen and (max-width: $screen-sm-max) {
        padding: .3em .4em;
      }
      @media screen and (max-width: $mobile-slider-breikpoint) {
        width: 65%;
        padding: 0;
        min-height: 60%;
      }
    }
  }
  @media screen and (max-width: 1599px) {
    font-size: 80px;
  }
  @media screen and (max-width: 1499px) {
    font-size: 75px;
  }
  @media screen and (max-width: 1399px) {
    font-size: 70px;
  }
  @media screen and (max-width: $screen-lg-max) {
    font-size: 60px;
  }
  @media screen and (max-width: $screen-md-max) {
    font-size: 46px;
  }
  @media screen and (max-width: $screen-sm-max) {
    font-size: 44px;
  }
  @media screen and (max-width: $mobile-slider-breikpoint) {
    font-size: 50px;
    .bnslider-vert {
      & > *:not(:first-child):not(.clearfix):not(.btn-slider) {
        margin-top: 20px;
      }
      & > .btn-wrap {
        margin-top: 0;
      }
    }
  }
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }
  @media screen and (max-width: 364px) {
    font-size: 36px;
  }
  .bnslider-text {
    line-height: 1em;
    color: $slide_content_text_color;
    margin-top: 1em;
    @include no-select;
    cursor: default;
    &:first-child {
      margin-top: 0;
    }
  }
  .bnslider-text--lg {
    font-size: 1em;
    font-weight: 700;
    margin-top: .25em;
    font-family: $heading_font_family;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: .84em;
    }
  }
  .bnslider-text--md {
    font-size: .74em;
    line-height: 1.2em;
    margin-top: 0.5em;
    font-weight: 600;
    font-family: $heading_font_family;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: .60em;
    }
  }
  .bnslider-text--sm {
    font-size: .48em;
    line-height: 1.2em;
    margin-top: 0.5em;
    font-weight: 700;
    letter-spacing: .05em;
    font-family: $heading_font_family;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: .60em;
      letter-spacing: .1em;
    }
  }
  .bnslider-text--xs {
    font-size: .24em;
    line-height: 1.2em;
    font-weight: 700;
    margin-top: 1em;
    letter-spacing: .05em;
    font-family: $heading_font_family;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: .32em;
      letter-spacing: .1em;
    }
  }
  .bnslider-text--xxs {
    font-size: .18em;
    font-weight: 500;
    margin-top: 1em;
    letter-spacing: .05em;
    font-family: $heading_font_family;
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: .28em;
      letter-spacing: .1em;
      font-weight: 500 !important;
    }
  }
  .bnslider-text--num {
    font-size: 2.2em;
    line-height: .7em;
    color: $slide-content-number_text_color;
    font-family: $heading_font_family;
    font-weight: 800;
  }
  .bnslider-text--num-text {
    font-size: 14px;
    line-height: 1.64em;
    color: $slide-content-number-text_text_color;
    letter-spacing: 1px;
    font-family: $heading_font_family;
    font-weight: 500;
    margin-left: 40px;
    padding-left: 28px;
    border-left-width: 1px;
    border-left-style: solid;
    border-color: $slide-content-number-text_text_color;
    margin-bottom: auto;
    &:not(:first-child) {
      margin-left: 40px;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 13px;
      padding-left: 28px;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: 12px;
      letter-spacing: 0;
      padding-left: 15px;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  .bnslider-text--num-wrap {
    @include flexbox();
    position: absolute;
    margin-left: -1.5em;
    z-index: -1;
    margin-top: -.3em !important;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
  .bnslider-text--num-wrap + * {
    margin-top: 1em !important;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0 !important;
    }
  }
  .bnslider-text p {
    font-size: 14px;
    line-height: 1.714em;
    @media screen and (max-width: $screen-md-max) {
      font-size: 12px;
      line-height: 1.2em;
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: 12px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .bnslider-text.text-center p {
    padding: 0 15%;
    margin: 0 auto;
  }
  .bnslider-text--lg + .bnslider-text--lg,
  .bnslider-text--md + .bnslider-text--md,
  .bnslider-text--sm + .bnslider-text--sm,
  .bnslider-text--xs + .bnslider-text--xs {
    margin-top: 0;
  }
  .bnslider-text.text-left {
    align-self: flex-start;
  }
  .bnslider-text.text-right {
    align-self: flex-end;
    &.bnslider-text--sm {
      margin-right: -0.3em;
    }
    &.bnslider-text--lg {
      margin-right: -0.15em;
    }
  }
  .bnslider-text.text-center {
    align-self: center;
  }
  .btn-slider.text-left {
    margin-right: auto;
    margin-left: 0;
  }
  .btn-slider.text-right {
    margin-left: auto;
    margin-right: 0;
  }
  .btn-slider.text-center {
    margin-left: auto;
    margin-right: auto;
  }

  .btn-wrap > .btn-decor:not(.btn-decor-sm) {
    margin-top: 0;
    padding: .55em 0 .417em;
    font-size: 24px;
    font-weight: 700;
    color: $slide-content-button_color;
    font-family: $heading_font_family;
    letter-spacing: 2.5px;
    span.btn-line {
      bottom: -.2em;
      height: 3px;
      background-color: $slide-content-button-line_color;
    }
    @media screen and (max-width: $screen-lg-max) {
      font-size: 20px;
      letter-spacing: 1.5px;
      span.btn-line {
        height: 3px;
      }
    }
    @media screen and (max-width: $screen-md-max) {
      font-size: 16px;
      span.btn-line {
        height: 2px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 16px;
      span.btn-line {
        height: 2px;
      }
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: 22px;
      span.btn-line {
        height: 3px;
      }
    }
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
    @media screen and (max-width: 364px) {
      font-size: 18px;
    }
    &:after {
      display: none;
    }
  }
  .btn-wrap > span > .btn-decor:not(.btn-decor-sm) {
    color: $slide-content-button_color;
    span.btn-line {
      bottom: -.2em;
      height: 2px;
      background-color: $slide-content-button-line_color;
    }
    &:after {
      display: none;
    }
  }
  @media screen and (min-width: $screen-md-min) {
    .aside .btn-wrap > .btn-decor:not(.btn-decor-sm) {
      font-size: .18em;
      padding-bottom: .35em;
    }
  }
  .btn-decor.btn-decor-sm:after {
    display: none;
  }
}

@media screen and (max-width: $mobile-slider-breikpoint) {
  .bnslider-text-content.txt-left .bnslider-text-content-flex .bnslider-vert.border-half,
  .bnslider-text-content.txt-right .bnslider-text-content-flex .bnslider-vert.border-half {
    width: 75%;
    padding-left: 0;
    padding-right: 0;
  }
  .bnslider-text-content.txt-left .bnslider-text-content-flex .bnslider-vert.border-half:after,
  .bnslider-text-content.txt-right .bnslider-text-content-flex .bnslider-vert.border-half:after {
    left: 15%;
    right: 15%;
  }
}

.bnslider-text-content.txt-right .bnslider-text-content-flex .bnslider-text--num-wrap {
  flex-direction: row-reverse;
  margin-left: .5em;
  .bnslider-text--num-text {
    margin-left: 0;
    margin-right: 40px;
    padding-right: 28px;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #868686;
    border-left: 0;
    &:not(:first-child) {
      margin-left: 30px;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-right: 28px;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      padding-right: 15px;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.bnslider .slick-dots li:not(.slick-active) button {
  background-color: $slide_nav_color;
}

.bnslider-dots {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  .slick-dots {
    pointer-events: none;
    width: auto;
    background-color: rgba(255, 255, 255, .55);
    border-radius: 13px;
    left: 50%;
    @include transform(translateX(-50%));
    li {
      pointer-events: auto;
    }
  }
  &.dots-white li button {
    background-color: #fff;
  }
  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  &.vert-dots {
    @include flexbox();
    @include align-items(center);
    .slick-dots {
      position: relative;
      @include transform(translateX(0));
      width: 27px;
    }
  }
  &.vert-dots:not(.vert-dots--left), &.vert-dots--right {
    .slick-dots {
      left: auto;
      right: 30px;
    }
    @include justify-content(flex-end);
    @media screen and (max-width: $screen-md-max) {
      .slick-dots {
        right: 15px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      .slick-dots {
        right: 0;
      }
    }
  }
  &.vert-dots:not(.vert-dots--left).container, &.vert-dots--right.container {
    .slick-dots {
      right: 0;
    }
  }
  &.vert-dots--left {
    .slick-dots {
      right: auto;
      left: 30px;
    }
    @include justify-content(flex-start);
    @media screen and (max-width: $screen-md-max) {
      .slick-dots {
        left: 15px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      .slick-dots {
        left: 0;
      }
    }
  }
  &.vert-dots--left.container {
    .slick-dots {
      left: 0;
    }
  }
}

.bnslider-arrows--toright > div {
  float: right;
  position: relative;
  min-width: 190px;
}

.bnslider-arrows--toleft > div {
  float: left;
  position: relative;
  min-width: 190px;
}

.bnslider-arrows {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: $screen-md-max) {
    left: 0;
    width: 100%;
    transform: translateX(0);
    bottom: 30px;
  }
  @media screen and (max-width: $mobile-slider-breikpoint) {
    bottom: 20px;
  }
  .slick-arrow {
    top: 0;
    font-size: 14px;
    line-height: 20px;
    width: auto;
    color: $slide_nav_color;
    text-transform: uppercase;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
    @media screen and (max-width: $mobile-slider-breikpoint) {
      font-size: 12px;
      line-height: 21px;
    }
  }
  .slick-prev, .slick-next {
    @include transition(.2s);
  }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 1;
    color: $slide_nav_color;
  }
  .slick-prev:focus,
  .slick-next:focus {
    opacity: 1;
    color: $slide_nav_color;
  }
  .slick-prev:before,
  .slick-next:before {
    position: absolute;
    top: 0;
    color: $slide_nav_color;
    opacity: 1;
    font-size: 20px;
    line-height: 20px;
    @include transition(.2s);
  }
  .slick-prev:before {
    left: 0;
    content: '\e92a';
  }
  .slick-next:before {
    right: 0;
    content: '\e929';
  }
  .slick-prev {
    left: 15px;
    padding-left: 30px;
  }
  &.container-fluid {
    .slick-prev {
      left: 30px;
      @media screen and (max-width: $screen-sm-max) {
        left: 15px;
      }
    }
  }
  .slick-next {
    right: 15px;
    padding-right: 30px;
  }
  &.container-fluid {
    .slick-next {
      right: 30px;
      @media screen and (max-width: $screen-sm-max) {
        right: 15px;
      }
    }
  }
  &.bnslider-arrows--bg {
    @media screen and (max-width: $mobile-slider-breikpoint) {
      bottom: 30px;
    }
    .slick-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      height: 35px;
      width: 110px;
      font-weight: 500;
      border-radius: 17px;
      &:before {
        position: relative;
      }
      &, &:hover {
        background: #cdcdcc;
      }
    }
    .slick-next {
      flex-direction: row-reverse;
    }
    .slick-prev:before {
      padding-right: 10px;
    }
    .slick-next:before {
      padding-left: 10px;
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .bnslider-arrows {
    .slick-prev:hover:before {
      left: -5px;
    }
    .slick-next:hover:before {
      right: -5px;
    }
  }
  .bnslider-arrows--pullright > div,
  .bnslider-arrows--pullleft > div {
    position: relative;
    width: 58%;
  }
  .bnslider-arrows--pullright > div {
    float: right;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .bnslider--fullheight ~ .bnslider-arrows {
    bottom: 55px;
  }
}

.bnslider.arrows-on .slide-caption {
  bottom: 20%;
}

.bnslider-wrapper .bnslider-dots {
  bottom: 20px;
  @media screen and (max-width: $screen-md-max) {
    bottom: 10px;
  }
  @media screen and (max-width: $mobile-slider-breikpoint) {
    bottom: 0;
  }
  .slick-dots li {
    width: 27px;
    height: 27px;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    vertical-align: top;
  }
  .slick-dots li:not(.slick-active) button {
    margin: 11px auto;
    background-color: #858383;
  }
  .slick-dots li.slick-active button {
    margin: 4px auto;
  }
}

[class*='col-'] .bnslider-wrapper {
  .bnslider-dots:not(.vert-dots) .slick-dots {
    bottom: calc(6% - 16px);
    width: auto;
    left: 50%;
    @include transform(translateX(-50%));
  }
  .container {
    max-width: 100%;
  }
  .bnslider-arrows {
    bottom: 6%;
    @media screen and (max-width: $screen-md-max) {
      bottom: 30px;
    }
    @media screen and (max-width: $mobile-slider-breikpoint) {
      bottom: 20px;
    }
  }
  .bnslider-dots {
    bottom: 0;
  }
  .bnslider-arrows .slick-prev {
    left: 7%;
  }
  .bnslider-arrows .slick-next {
    right: 7%;
  }
}

.bnslider--darktext {
  ~ .bnslider-arrows .slick-arrow, ~ .bnslider-arrows .slick-prev:before, ~ .bnslider-arrows .slick-next:before {
    color: $slide-dark_nav_color;
    font-weight: 700;
  }
  ~ .bnslider-arrows .slick-prev:hover, ~ .bnslider-arrows .slick-next:hover {
    color: $slide-dark_nav_color;
    font-weight: 700;
  }
  .bnslider-text-content .bnslider-text-content-flex .bnslider-text {
    color: $slide-content-dark_text_color;
  }
  .bnslider-text-content .bnslider-text-content-flex .btn-wrap > .btn-decor {
    color: $slide-content-dark-button_color;
  }
  .bnslider-text-content .bnslider-text-content-flex .btn-wrap > .btn-decor .btn-line {
    background-color: $slide-content-dark-button-line_color;
  }
}

.bnslider--darkarrows {
  ~ .bnslider-arrows .slick-arrow, ~ .bnslider-arrows .slick-prev:before, ~ .bnslider-arrows .slick-next:before {
    color: $slide-dark_nav_color;
    font-weight: 700;
  }
  ~ .bnslider-arrows .slick-prev:hover, ~ .bnslider-arrows .slick-next:hover {
    color: $slide-dark_nav_color;
    font-weight: 700;
  }
}

.aside {
  .bnslider-text-content .bnslider-text-content-flex .bnslider-text--num-wrap + * {
    @media screen and (min-width: 1600px) {
      margin-top: .5em !important;
    }
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0 !important;
    }
  }
  .bnslider-text-content .bnslider-text-content-flex .bnslider-vert > .btn-wrap {
    margin-top: 15px;
  }
  .bnslider-text-content .bnslider-text-content-flex .bnslider-vert > *:not(:first-child):not(.clearfix):not(.btn-wrap) {
    margin-top: 15px;
  }
  .bnslider-text-content .bnslider-text-content-flex {
    .bnslider-text--lg {
      font-size: .7em;
    }
    .bnslider-text--md {
      font-size: .5em;
      line-height: 1em;
    }
    .bnslider-text--sm {
      font-size: .34em;
    }
    .bnslider-text--xs {
      font-size: .18em;
    }
    .bnslider-text--xxs {
      font-size: .16em;
    }
    .bnslider-text--num {
      font-size: 1.7em;
    }
  }
}

@media screen and (min-width: 1800px) {
  .bnslider-with-tabs .bnslider-text-content .bnslider-text-content-flex .bnslider-vert {
    margin-left: -145px;
  }
}

@media (min-width: 1600px) and (max-width: 1799px) {
  .bnslider-with-tabs .bnslider-text-content .bnslider-text-content-flex .bnslider-vert {
    margin-left: -95px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .bnslider-with-tabs .bnslider-text-content .bnslider-text-content-flex .bnslider-vert {
    margin-left: -50px;
  }
}

@media (min-width: $mobile-menu-breikpoint) and (max-width: 1399px) {
  .bnslider-with-tabs .bnslider-text-content .bnslider-text-content-flex .bnslider-vert {
    margin-left: 20px;
  }
}

@media screen and (min-width: $mobile-menu-breikpoint) {
  .bnslider-with-tabs {
    overflow: visible;
    height: 100vh;
    min-height: 700px;
  }
}

@media screen and (max-width: ($mobile-menu-breikpoint - 1)) {
  .bnslider-with-tabs {
    height: calc(100vh - 130px);
    max-height: 460px;
    .bnslider-text-content-flex {
      margin-top: -10%;
    }
  }
}

.bnslider-with-tabs .slick-dots {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  @include flexbox();
  li {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    background-color: $header-tabs_bg;
    flex: 1;
    text-align: center;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    @include transition(.2s);
    span {
      display: block;
      padding: 20px;
      text-decoration: none;
      font-size: 14px;
      line-height: 1em;
      font-weight: 500;
      font-family: $heading_font_family;
      text-transform: uppercase;
      color: $header-tabs_color;
    }
    &.slick-active {
      position: relative;
      background-color: transparent;
      color: $header-tabs-active_color;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.15);
      }
      span {
        color: #ffffff;
      }
    }
  }
}

.bnslider-pricing {
  padding: 20px 30px;
  background-color: rgba(0, 0, 0, .65);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  width: 360px;
  max-width: 100%;
  @media screen and (max-width: $screen-sm-max) {
    padding: 10px 15px;
    font-size: 13px;
    line-height: 18px;
  }
  table {
    border: 0;
    width: 100%;
    td {
      padding: 12px 0;
      vertical-align: center;
      @media screen and (max-width: $screen-sm-max) {
        padding: 5px 0;
      }
    }
    td:last-child {
      text-align: right;
    }
  }
  &, a {
    color: #ffffff;
  }
  a {
    text-decoration: none;
    @include transition(.2s);
    &:hover {
      opacity: .8;
    }
  }
}

.bnslider-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  @include flexbox();
  @include transition(.75s ease .75s);
}

.slick-initialized + .bnslider-loader {
  opacity: 0;
}

.snow-effect {
  position: absolute;
  overflow: hidden;
  top: 0;
  pointer-events: none;
}