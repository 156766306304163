.panel-collapse {
    &.completed {
        .panel-body {
            line-height: 18px;
        }
    }
}

.checkout-accordion {
    .panel {
        margin-bottom: 10px;

        .panel-heading {
            background-color: $minicart-drop-total_bg;
            padding: 0 15px;
            border: 0;

            &:after,
            &:before {
                right: 15px;
            }
        }

        .panel-body {
            padding: 15px;
            border: 0;

            .checkout-mode-radio {
                label {
                    margin: 0;
                }
            }

            .checkout-mode-short-text {
                padding-left: 30px;
                margin-bottom: 20px;
            }

            .checkout-accordion-content {
                padding-left: 30px;
                font-size: 12px;
            }
        }
    }
}
.pay-for-purchase-container{
    h3.headline{
        padding: 5px 10px;
        background-color: #fbfbfb;
    }
}

.cart-table-prd-qty b,
.cart-table-prd-price b {
    letter-spacing: 0;
}

.payment-footer-buttons {
    .btn-lg {
        padding: 0.5rem 1rem !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
    }
}

.checkout-order-summary-container {
    position: sticky;
    top: 90px;
    @include screen-sm-down {
        margin-top: 5px;
    };
}

.back-link{
    margin-top: 20px;
}