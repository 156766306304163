.testimonials-item {
  padding-left: .5px;
  padding-right: .5px;
}

.testimonials-item-text {
  color: $testimonials_color;
  & + * {
    margin-top: 20px;
  }
}

.testimonials-item-photo {
  flex: 0 0 81px;
  max-width: 81px;
  &:not(:last-child) {
    margin-right: 20px;
  }
  img {
    width: 100%;
  }
}

.testimonials-item-name {
  font-size: 14px;
  &, a {
    color: $testimonials_name_color;
  }
  a {
    text-decoration: none;
    &:hover {
      color: $custom_color;
    }
  }
}

.testimonials-item-date {
  font-size: 12px;
  color: $testimonials_date_color;
}

.testimonials-item:not(.slick-slide) + .testimonials-item {
  margin-top: 20px;
  padding-top: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $decor_border_color;
}