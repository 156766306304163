.product-slider {
  &.vert-dots.vert-dots--left .slick-dots {
    left: 5.35%;
  }
  @media screen and (max-width: $screen-sm-max) {
    .slick-dots {
      bottom: 10px;
      @include justify-content(center);
    }
  }

  .product-slider-item {
    position: relative;
    background-color: $product-single-slider-item_bg;
    @media screen and (max-width: $screen-md-max) {
      background-color: transparent;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 50px;
    }
    img {
      max-width: 60%;
      float: right;
      @media screen and (max-width: $screen-md-max) {
        max-width: 100%;
        float: none;
        margin: 0 auto 30px;
      }
    }
  }
  .product-slider-inside {
    @include fullAbsolute();
    @media screen and (max-width: $screen-md-max) {
      position: relative;
    }
  }
  .prd-tag {
    &:not(:first-child) {
      margin-top: 20px;
    }
    font-size: 12px;
    line-height: 1em;
    text-transform: uppercase;
    color: $product-single-slider-item-tag_color;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
      & + * {
        margin-top: 0;
      }
    }
  }
  .prd-title, .prd-title > a {
    color: $product-single-slider-item-title_color;
  }
  .prd-title {
    &:not(:first-child) {
      margin-top: 14px;
    }
    margin-bottom: 0;
    font-size: 48px;
    line-height: 1em;
    font-weight: 800;
    text-decoration: none;
    transition: 0.2s;
    font-family: $heading_font_family;
    letter-spacing: 1px;
    @media screen and (max-width: $screen-md-max) {
      font-size: 36px;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
    }
    @media screen and (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
  .prd-price {
    @include flexbox();
    @include align-items(center);
    flex-wrap: wrap;
    &:not(:first-child) {
      margin-top: 20px;
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 16px;
      }
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 10px;
      }
    }
    .price-new {
      font-size: 30px;
      line-height: 1em;
      font-weight: 700;
      font-family: $heading_font_family;
      color: $product-single-slider-item-price_color;
      @media screen and (max-width: $screen-md-max) {
        font-size: 24px;
      }
      @media screen and (max-width: $screen-sm-max) {
        font-size: 20px;
      }
      @media screen and (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }
    .price-comment, .price-old {
      display: none;
    }
  }
  .prd-action form {
    @include flexbox();
    @include align-items(center);
    flex-wrap: wrap;
    .form-group:not(:only-child), .form-flex:not(:only-child) {
      margin-bottom: 0;
    }
  }
  .prd-links {
    margin: 0 10px;
  }
  .prd-action-wrap {
    &:not(:first-child) {
      margin-top: 38px;
      @media screen and (max-width: $screen-md-max) {
        margin-top: 24px;
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 16px;
      }
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 10px;
      }
    }
  }
  .prd-rating {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .product-slider-info > *:first-child {
    margin-top: 0;
  }

  .product-slider-info {
    width: 43%;
    height: 100%;
    margin: 0 6%;
    color: $product-single-slider-item-info_color;
    @include flexbox();
    @include flex-direction(column);
    &.text-center {
      text-align: center;
      .prd-rating, .prd-price, .prd-action {
        justify-content: center;
      }
      .prd-action .btn {
        margin-right: 0;
      }
    }
    @media screen and (max-width: $screen-md-max) {
      width: 100%;
      margin: 0;
    }
  }
}

.aside .product-slider .prd-title {
  font-size: 32px;
}

.product-slider-2 {
  .slick-dots {
    position: relative;
    bottom: -20px;
  }
  @media screen and (max-width: $screen-sm-max) {
    .slick-dots {
      bottom: 30px;
    }
  }
  .product-slider-item {
    position: relative;
    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
      display: table;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-bottom: 50px;
      text-align: center;
    }
    img {
      width: 56%;
      float: right;
      @media screen and (max-width: $screen-sm-max) {
        width: 90%;
        float: none;
        margin: 0 auto 15px;
      }
      @media screen and (max-width: $screen-xs-max) {
        width: 80%;
      }
    }
  }
  .product-slider-inside {
    @include fullAbsolute();
    @media screen and (max-width: $screen-md-max) {
      position: relative;
    }
  }
  .prd-title {
    &:not(:first-child) {
      margin-top: 14px;
    }
    margin-bottom: 0;
    font-size: 48px;
    line-height: 1em;
    font-weight: 800;
    text-decoration: none;
    transition: 0.2s;
    color: $product-single-slider2-item-title_color;
    font-family: $heading_font_family;
    letter-spacing: 1px;
    @media screen and (max-width: $screen-md-max) {
      font-size: 36px;
    }
    @media screen and (max-width: $screen-sm-max) {
      font-size: 24px;
    }
    @media screen and (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
  .prd-tag {
    position: relative;
    top: 1px;
    margin-top: 0;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: $product-single-slider2-item-tag_color;
    & + * {
      margin-left: 10px;
    }
  }
  .prd-rating {
    margin-top: 0;
  }
  .prd-price {
    &:not(:first-child) {
      margin-top: 35px;
      @media screen and (max-width: $screen-md-max) {
        margin-top: 24px;
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 16px;
      }
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 10px;
      }
    }
    font-size: 30px;
    line-height: 1em;
    font-weight: bold;
    font-family: $heading_font_family;
    color: $product-single-slider2-item-price_color;
    @media screen and (max-width: $screen-sm-max) {
      @include justify-content(center);
    }
    @media screen and (max-width: $screen-xs-max) {
      font-size: 22px;
    }
  }
  .prd-action {
    &:not(:first-child) {
      margin-top: 65px;
      @media screen and (max-width: $screen-md-max) {
        margin-top: 50px;
      }
      @media screen and (max-width: $screen-sm-max) {
        margin-top: 25px;
      }
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 10px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      @include justify-content(center);
    }
  }
  .prd-action form {
    @include flexbox();
    @include align-items(center);
    flex-wrap: wrap;
    .form-group:not(:only-child), .form-flex:not(:only-child) {
      margin-bottom: 0;
    }
    @media screen and (min-width: $screen-md-min) {
      .btn {
        padding: 16px 30px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
      .select-wrapper select {
        height: 52px;
        font-size: 12px;
        line-height: 22px;
        padding: 8px 26px 8px 16px;
        border-right: 0;
      }
      .select-wrapper:before {
        line-height: 50px;
        min-width: 0;
      }
      .select-wrapper + .btn {
        padding: 14px 20px;
        max-width: none;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        [class*="icon-"] {
          font-size: 20px;
        }
      }
    }
  }
  .prd-links {
    margin: 0 15px;
  }
  .d-flex {
    &:not(:first-child) {
      margin-top: 15px;
      @media screen and (max-width: $screen-xs-max) {
        margin-top: 5px;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      @include justify-content(center);
    }
  }
  .prd-description {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: $product-single-slider2-item-info_color;
    &:not(:first-child) {
      margin-top: 55px;
      @media screen and (max-width: $screen-md-max) {
        margin-top: 40px;
      }
      @media screen and (max-width: $screen-md-max) {
        display: none;
      }
    }
  }

  .product-slider-info > *:first-child {
    margin-top: 0;
  }

  .product-slider-info {
    width: 42%;
    height: 100%;
    color: $product-single-slider2-item-info_color;
    @include flexbox();
    @include flex-direction(column);
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
      margin: 0;
      text-align: center;
    }
  }
  @media screen and (min-width: 1400px) {
    &.slick-arrows-squared .slick-prev {
      left: -95px;
    }
    &.slick-arrows-squared .slick-next {
      right: -95px;
    }
  }
  @media (max-width: 1399px) and (min-width: $screen-xl-min) {
    &.slick-arrows-squared .slick-prev {
      left: -44px;
    }
    &.slick-arrows-squared .slick-next {
      right: -44px;
    }
  }
  @media (max-width: 1399px) and (min-width: $screen-lg-min) {
    &.slick-arrows-squared .slick-prev {
      left: -30px;
    }
    &.slick-arrows-squared .slick-next {
      right: -30px;
    }
  }
  @media (max-width: $screen-lg-max) and (min-width: $screen-md-min) {
    &.slick-arrows-squared .slick-prev {
      left: -44px;
    }
    &.slick-arrows-squared .slick-next {
      right: -44px;
    }
  }
  @media (max-width: $screen-md-max) {
    &.slick-arrows-squared .slick-prev {
      left: 15px;
    }
    &.slick-arrows-squared .slick-next {
      right: 15px;
    }
  }
}