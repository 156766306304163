.post-prws {
  margin: -30px -15px 0 -15px;
  width: calc(100% + 30px);
  @include respond-to(sm-only) {
    margin-top: -25px;
  }
  @include respond-to(xs-only) {
    margin-top: -15px;
  }
}

.post-prw {
  .post-img {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    img {
      width: calc(100% + 1px);
      @include transform(scale(1.1) rotate(.001deg));
      @include transition (.5s);
    }
    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0;
      @include transition (.3s);
    }
  }
  &:hover {
    .post-img img {
      @include transform(scale(1) rotate(.01deg));
    }
    .post-img:after {
      opacity: .15;
    }
  }
  .post-img + *:not(:first-child) {
    margin-top: 20px;
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 15px;
    }
  }
  & > * {
    margin-bottom: 0;
  }
  & > *:not(:first-child) {
    margin-top: 15px;
    @media (max-width: $screen-sm-max) {
      margin-top: 10px;
    }
  }
  & > *:not(.post-img) {
    padding-right: 10px;
    padding-left: 10px;
    @media screen and (max-width: $screen-xs-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .post-title {
    display: inline-block;
    text-decoration: none;
    color: $heading_color;
    &:hover,
    &:focus,
    &:hover > *,
    &:focus > * {
      color: $custom_color;
      text-decoration: none;
    }
    & > * {
      display: inline-block;
      margin: 0;
    }
  }
  .tags-list:not(:first-child) {
    margin-bottom: -10px;
    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 0;
    }
  }
  .post-bot {
    position: relative;
    margin-top: 15px;
    padding: 15px 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    overflow: hidden;
    @media (max-width: $screen-sm-max) {
      padding: 15px 0;
      flex-wrap: wrap;
    }
    &:before {
      position: absolute;
      top: 0;
      right: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: 1px;
      background-color: $decor_border_color_darker;
      content: '';
    }
    & > * {
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 20px;
    }
    & > *:last-child {
      margin-left: auto;
    }
    & > *:first-child {
      margin-left: 0;
    }
  }
  .post-link {
    color: $text_color_dark;
    &:hover,
    &:focus {
      color: $custom_color;
      text-decoration: none;
    }
    @media (max-width: $screen-sm-max) {
      margin-left: auto;
    }
  }
  .post-action {
    a[class*='icon'] {
      padding: 0 5px;
      color: $custom_color;
      font-size: 14px;
      &:hover,
      &:focus {
        color: $text_color_dark;
        text-decoration: none;
      }
    }
    a.icon-share {
      font-size: 17px;
    }
  }
}

@media (min-width: $screen-md-min) {
  .aside-block .post-prws-carousel {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .aside-block .post-prws-carousel .post-prw {
    width: calc(100% - 30px);
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: $screen-sm-max) {
  .aside-block .post-prws-carousel {
    margin-left: -5px;
    margin-right: -5px;
    width: 100%;
  }
  .aside-block .post-prws-carousel .post-prw {
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }
}

.post-prw-big {
  .tags-list:not(:first-child) {
    margin-top: 20px;
  }
  .post-bot {
    padding: 28px 0 20px;
  }
  .post-img {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    img {
      width: calc(100% + 1px);
      @include transform(scale(1.1) rotate(.001deg));
      @include transition (.5s);
    }
    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0;
      @include transition (.3s);
    }
  }
  &:hover {
    .post-img img {
      @include transform(scale(1) rotate(.01deg));
    }
    .post-img:after {
      opacity: .15;
    }
  }
}

.post-full {
  .tags-list:not(:first-child) {
    margin-top: 15px;
  }
  .post-bot {
    padding: 18px 0 0;
  }
  &:before {
    top: -10px;
  }
}

.post-prw-big, .post-full {
  & > *:not(:first-child) {
    margin-top: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 24px;
    }
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 18px;
    }
  }
  .post-img {
    display: block;
    img {
      width: 100%;
    }
  }
  .post-title {
    display: inline-block;
    margin: 14px 0 0 0;
    text-decoration: none;
    &:hover,
    &:focus,
    &:hover > *,
    &:focus > * {
      color: $custom_color;
      text-decoration: none;
    }
    & > * {
      display: inline-block;
      margin: 0;
    }
  }
  .post-title:first-child {
    margin-top: 0;
  }
  .post-teaser:not(:first-child) {
    margin-top: 21px;
  }
  .post-bot {
    position: relative;
    &:not(:first-child) {
      margin-top: 20px;
    }
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $decor_border_color_darker;
      content: '';
    }
    & > * {
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 20px;
    }
    & > *:last-child {
      margin-left: auto;
    }
    @media screen and (max-width: $screen-sm-max) {
      & > .social-list:last-child {
        margin-top: 5px;
        margin-left: -12px;
        min-width: 100%;
      }
      & > .post-action:not(:last-child) {
        margin-left: auto;
      }
    }
    & > *:first-child {
      margin-left: 0;
    }
  }
  .post-link {
    color: $text_color_dark;
    font-weight: 600;
    &:hover,
    &:focus {
      color: $custom_color;
      text-decoration: none;
    }
  }
  .post-date {
    font-size: 12px;
    line-height: 1em;
    color: $text_color_light;
    text-transform: uppercase;
  }
  .post-action {
    a[class*='icon'] {
      padding: 0 5px;
      color: $custom_color;
      font-size: 14px;
      &:hover,
      &:focus {
        color: $text_color_dark;
        text-decoration: none;
      }
    }
    a.icon-share {
      font-size: 17px;
    }
  }
}

.post-prws-carousel:not(.slick-initialized) {
  height: 372px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.post-prws-carousel .post-prw {
  float: left;
  margin-top: 30px;
  margin-right: 15px;
  margin-left: 15px;
  width: 50%;
  @include respond-to(md-only) {
    margin-top: 30px;
  }
  @include respond-to(sm-only) {
    margin-top: 25px;
  }
  @include respond-to(xs-only) {
    margin-top: 15px;
  }
}

.aside-block .post-prw:not(.slick-slide) + .post-prw {
  margin-top: 20px;
  @include respond-to(xs-only) {
    margin-top: 15px;
  }
}

.post-prw-big + .post-prw-big {
  margin-top: 60px;
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 40px;
  }
}

/* blog post grid */
.post-grid-2, .post-grid-3, .post-grid-4 {
  width: calc(100% + 30px);
  margin: 10px 0 0 -30px;
}

.post-grid-2 .post-prw-big,
.post-grid-3 .post-prw-big,
.post-grid-4 .post-prw-big {
  vertical-align: top;
  display: inline-block;
  width: calc(50% - 30px);
  min-height: 1px;
  margin-left: 30px;
  margin-right: -4px;
  margin-bottom: 30px;
  margin-top: 0;
}

.post-grid-2 .post-prw-big:nth-child(1),
.post-grid-2 .post-prw-big:nth-child(2),
.post-grid-3 .post-prw-big:nth-child(1),
.post-grid-3 .post-prw-big:nth-child(2),
.post-grid-3 .post-prw-big:nth-child(3),
.post-grid-4 .post-prw-big:nth-child(1),
.post-grid-4 .post-prw-big:nth-child(2),
.post-grid-4 .post-prw-big:nth-child(3),
.post-grid-4 .post-prw-big:nth-child(4) {
  margin-top: 10px;
}

.post-grid-2 .post-prw-big {
  width: calc(50% - 30px);
}

.post-grid-3 .post-prw-big {
  width: calc(33.33333% - 30px);
}

.post-grid-4 .post-prw-big {
  width: calc(25% - 30px);
}

@media screen and (max-width: $screen-md-max) {
  .post-grid-4 .post-prw-big {
    width: calc(33.33333% - 30px);
  }
}

@media screen and (max-width: $screen-sm-max) {
  .post-grid-4 .post-prw-big, .post-grid-3 .post-prw-big {
    width: calc(50% - 30px);
  }
}

@media screen and (max-width: 480px) {
  .post-grid-4 .post-prw-big, .post-grid-3 .post-prw-big, .post-grid-2 .post-prw-big {
    width: calc(100% - 30px);
  }
}

#sideColumn.fixed-col {
  margin-top: 0;
}

.post-comment-form {
  &:not(:first-child) {
    margin-top: 40px;
  }
  .comment-form {
    margin-top: 40px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }
}

.post-comments {
  &:not(:first-child) {
    margin-top: 30px;
  }
}

.post-comment {
  &:first-of-type:not(:first-child) {
    margin-top: 40px;
  }
  & > .row > [class*='col']:last-child {
    padding-bottom: 25px;
  }
  & > .row > [class*='col']:last-child:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 0;
    height: 1px;
    background-color: $decor_border_color_darker;
  }
  &:not(:first-child) {
    margin-top: 25px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }
  img {
    max-width: 100%;
  }
}

.post-comment-text {
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.post-comment-author-img {
  max-width: 100px;
}

.post-comment-author {
  font-size: 14px;
  line-height: 1em;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  &, a {
    color: $heading_color;
  }
  &:not(:first-child) {
    margin-top: 20px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 15px;
    }
  }
}

.post-comment-date {
  font-size: 12px;
  line-height: 1em;
  color: $text_color_light;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.post-comment-reply {
  font-size: 12px;
  line-height: 1em;
  &, a {
    color: $custom_color
  }
  a:hover {
    color: $text_color_dark;
  }
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 25px;
  }
}