@import "overwrites/variables";
@import "../../core/mixins";

@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap&subset=latin-ext');
@import "../../core/custom-mixins";

@import "overwrites/style-all";
@import "overwrites/style-light";

@import "../../core/boostrap-fix/zamnia-bootstrap-fix";

@import "overwrites/custom";
@import "../../blocks/cart";
@import "../../blocks/checkout";

@import "../../blocks/profile";
@import "../../blocks/loading";
@import "../../blocks/mfp";
@import "../../blocks/popup";
@import "../../blocks/addresses";
@import "../../blocks/fancybox";
@import "../../blocks/oldalinfo";
@import "../../blocks/highlights";
@import "../../blocks/productfilter";


.input-group > .select-wrapper{
  position: relative;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

//XS
@media (max-width: $screen-xs-max) {
  .hidden-xs,
  .hidden-xs-up,

  .hidden-sm-down,
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down
  {
    display: none !important;
  }

  .input-100-xs,
  .input-100-xs-up,
  .input-100-lg-down,
  .input-100-md-down,
  .input-100-sm-down,
  .input-100-xl-down
  {
    width: 100% !important;
  }
}
//SM
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .hidden-sm,
  .hidden-sm-up,
  .hidden-xs-up,

  .hidden-xl-down,
  .hidden-lg-down,
  .hidden-md-down,
  .hidden-sm-down {
    display: none !important;
  }

  .input-100-xs-up,
  .input-100-sm-up,
  .input-100-lg-down,
  .input-100-md-down,
  .input-100-sm-down,
  .input-100-xl-down {
    width: 100% !important;
  }
}
//MD
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .hidden-md,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,

  .hidden-xl-down,
  .hidden-lg-down,
  .hidden-md-down {
    display: none !important;
  }

  .input-100-xs-up,
  .input-100-sm-up,
  .input-100-md-up,
  .input-100-lg-down,
  .input-100-md-down,
  .input-100-xl-down {
    width: 100% !important;
  }
}
//LG
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  .hidden-lg,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,

  .hidden-xl-down,
  .hidden-lg-down {
    display: none !important;
  }

  .input-100-xs-up,
  .input-100-sm-up,
  .input-100-md-up,
  .input-100-lg-up,
  .input-100-md-down,
  .input-100-xl-down {
    width: 100% !important;
  }
}
//XL
@media (min-width: $screen-xl-min) {
  .hidden-xl,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,
  .hidden-xl-up,

  .hidden-xl-down
  {
    display: none !important;
  }

  .input-100-xs-up,
  .input-100-sm-up,
  .input-100-md-up,
  .input-100-lg-up,
  .input-100-md-up,
  .input-100-xl-down {
    width: 100% !important;
  }
}

.unavailable-text{
  font-weight:600;
  font-size:16px;
  color:#efa300;
  width:100%;

}
