.tab-category.nav-tabs {
  border-bottom: 0;
  & > * {
    display: inline-block;
    float: none;
    margin: 0 15px;
    & > * {
      margin-bottom: 0;
    }
  }
  &:not(.text-center):not(.justify-content-center) > *:first-child {
    margin-left: 0;
  }
  &:not(.text-center):not(.justify-content-center) > *:last-child {
    margin-right: 0;
  }
  .nav-link.active, .nav-link:hover {
    color: $custom_color;
  }
  .nav-link {
    padding: 0;
    border: 0;
    @media (max-width: $screen-sm-max) {
      padding: 5px 0;
    }
  }
}

.tab-category-content.tab-content {
  & > .tab-pane {
    padding: 0;
  }
}

.tab-category-image {
  display: block;
  height: 100%;
  min-height: 300px;
  text-decoration: none;
  background-size: cover;
  background-position: center;
}