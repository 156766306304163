/* header 07 */
@media screen and (min-width: $mobile-menu-breikpoint) {
  .hdr-style-7 {
    .hdr-content {
      padding: 38px 0;
      & > .container {
        @include flexbox();
        text-align: center;
        & > * {
          flex: 1 0 0;
        }
        .hdr-content-right {
          text-align: right;
        }
        .hdr-content-left {
          text-align: left;
        }
        .hdr-content-right,
        .hdr-content-left {
          & > * {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .links-holder {
          & > * {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .minicart {
          margin-left: 0;
        }
      }
    }
    .logo {
      width: 200px;
      margin: 0 auto;
    }
    .nav-holder {
      margin: 0;
      .hdr-nav {
        margin: 0;
        padding: 15px 0;
        min-height: 50px;
        border-bottom: 1px solid $header_border_color;
      }
    }
    .dropdn-link {
      padding: 10px 0;
    }
    .hdr-content-right {
      .dropdn-link span {
        order: -1;
      }
      .dropdn-link .icon, .header-inline-link a .icon {
        padding-left: 12px;
        padding-right: 0;
      }
      & > * + * {
        margin-left: 15px;
      }
    }
    .hdr-content-left {
      & > * + * {
        margin-left: 15px;
      }
    }
    .dropdn_settings .dropdn-content .container {
      display: flex;
      @include justify-content(space-between);
      ul li span + span {
        display: none;
      }
      .vert-divider {
        width: 1px;
        background-color: $header_border_color;
      }
    }
    .mmenu > li > a {
      position: relative;
      padding: 18px 18px;
    }
    .mmenu-submenu {
      margin-top: -1px;
    }
  }
}

.hdr-style-7 {
  .dropdn_settings .dropdn-link > .icon {
    font-size: 22px;
  }
  .dropdn_settings .dropdn-content .container,
  .mobilemenu-settings .dropdn-content .container {
    display: flex;
    @include justify-content(space-between);
    ul li span + span {
      display: none;
    }
    .vert-divider {
      width: 1px;
      background-color: $header_border_color;
    }
  }
}

@media screen and (max-width: ($mobile-menu-breikpoint - 1)) {
  .hdr-style-7 {
    .mobilemenu-settings .dropdn-content .container {
      flex-direction: column;
      .vert-divider {
        display: none;
      }
    }
    .mobilemenu-settings {
      display: inline-block;
      position: static;
      .dropdn-link .icon {
        font-size: 23px;
      }
    }
  }
}