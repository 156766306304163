.tooltip {
  z-index: 1000;
  &.top {
    padding: $tooltip-arrow-border-width 0;
  }
  &.right {
    padding: 0 $tooltip-arrow-border-width;
  }
  &.bottom {
    padding: $tooltip-arrow-border-width 0;
  }
  &.left {
    padding: 0 $tooltip-arrow-border-width;
  }
}

.tooltip-inner {
  border-width: $tooltip_border_width;
  border-style: solid;
  border-color: $tooltip_border_color;
  color: $tooltip_text_color;
  font-size: 12px;
  border-radius: 0;
}

.tooltip-inner {
  background: $tooltip_bg;
  padding: 2px 10px 2px;
}

.tooltip .arrow {
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip .arrow:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  background: $body_bg;
  border-color: transparent;
  border-style: solid;
  z-index: -1;
}

.tooltip {
  &.bs-tooltip-top .arrow {
    bottom: 3 * $tooltip_border_width;
    top: auto;
    width: auto;
    border-width: 5px 5px 0;
    border-top-color: $tooltip_bg;
    &:before {
      bottom: -2 * $tooltip_border_width;
      top: auto;
      left: 50%;
      margin-left: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
      border-top-color: $tooltip_border_color;
    }
  }
  &.bs-tooltip-bottom .arrow {
    top: 3 * $tooltip_border_width;
    width: auto;
    border-width: 0 5px 5px;
    border-bottom-color: $tooltip_bg;
    &:before {
      top: -2 * $tooltip_border_width;
      left: 50%;
      margin-left: -$tooltip-arrow-border-width;
      border-width: 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
      border-bottom-color: $tooltip_border_color;
    }
  }
  &.bs-tooltip-left .arrow {
    right: 3 * $tooltip_border_width;
    width: auto;
    border-width: 5px 0 5px 5px;
    border-left-color: $tooltip_bg;
    &:before {
      right: -2 * $tooltip_border_width;
      left: auto;
      top: 50%;
      margin-top: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
      border-left-color: $tooltip_border_color;
    }
  }
  &.bs-tooltip-right .arrow {
    left: 2 * $tooltip_border_width;
    width: auto;
    border-width: 5px 5px 5px 0;
    border-right-color: $tooltip_bg;
    &:before {
      left: -2 * $tooltip_border_width;
      top: 50%;
      margin-top: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
      border-right-color: $tooltip_border_color;
    }
  }
}