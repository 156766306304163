textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus, textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {
  @include box-shadow-clear;
  border-color: $form_border_color;
  border-style: solid;
  border-width: 1px;
  outline: 0 none;
}

input, textarea {
  border-radius: 0;
}

button, input, optgroup, select, textarea {
  color: inherit;
}

input[type=text], input[type=email], input[type=password], textarea, .select-wrapper select, .select-wrapper-sm select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  line-height: 1.5em;
}

select.input-sm {
  height: 34px;
  line-height: 34px;
}

.form-control {
  @include box-shadow-clear;
  @include input-placeholder($form_color);
  background-color: $form_bg;
  border-color: $form_border_color;
  border-radius: 0;
  color: $form_color;
  font-size: 13px;
  height: 40px;
  line-height: 16px;
  padding: 12px 15px 12px;
  &, &:focus {
    @include box-shadow-clear;
    border-color: $form_border_color;
    border-style: solid;
    border-width: 1px;
    outline: 0 none;
  }
  &:focus {
    @include input-placeholder($form_color_light);
  }
  &.textarea--height-50 {
    height: 40px;
  }
  &.textarea--height-100 {
    height: 102px;
  }
  &.textarea--height-200 {
    height: 198px;
  }
}

.form-control--error {
  @include input-placeholder($form-error_color);
  border-color: $form-error_border_color;
  color: $form-error_color;
  &[type]:focus {
    border-color: $form-error_border_color;
  }
  &:focus {
    @include input-placeholder($form-error_color);
  }
}

.form-control--full {
  margin-bottom: 10px;
  width: 100%;
}

.form-group:not(:only-child), .form-flex:not(:only-child) {
  margin-bottom: 11px;
}

.form-group:only-child, .form-flex:only-child {
  margin-bottom: 0;
}

.form-flex {
  @include flexbox();
  .form-group {
    flex: 1;
    margin-bottom: 0;
  }
  & > * {
    margin: 0 7.5px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  & > *:first-child {
    margin-left: 0;
  }
}

.select-wrapper, .select-wrapper-sm {
  position: relative;
  &:before {
    background-color: $form-element_bg;
    bottom: 1px;
    color: $form-element_symbol_color;
    content: '\e921';
    font-family: 'icomoon';
    font-size: 11px;
    line-height: 40px;
    pointer-events: none;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 1px;
    width: 40px;
  }
  select {
    background-color: $form_bg;
    padding: 0 44px 0 14px;
  }
}

.select-wrapper-sm {
  .form-control {
    height: 37px;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.select-wrapper-sm {
  &:before {
    font-size: 11px;
    line-height: 36px;
    width: 30px;
  }
  select {
    padding-right: 30px;
  }
}

.qty-changer input[type="button"],
.qty-changer input[type="button"]
{
  @include transition (border-color 0.2s ease);
  background: $qty-changer_button_bg;
  background-color: transparent;
  border: 0;
  border-color: $qty-changer_button_border_color;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: $qty-changer_button_color;
  font-size: 12px;
  height: 27px;
  line-height: 12px;
  min-width: 27px;
  padding: 7px 5px 8px;
  text-align: center;
  text-transform: uppercase;
  -webkit-appearance: none;
  &:hover {
    background: $qty-changer_button_hover_bg;
    border-color: $qty-changer_button_hover_border_color;
    color: $qty-changer_button-hover_color;
  }
}

.qty-changer--lg input[type="button"] {
  height: 36px;
  min-width: 36px;
}

.qty-changer input[type="text"] {
  background: $qty-changer_input_bg;
  border: 0;
  border-color: $qty-changer_input_border_color;
  border-radius: 0;
  color: $qty-changer_input_color;
  font-size: 12px;
  font-weight: bold;
  line-height: 27px;
  padding: 0;
  text-align: center;
  -webkit-appearance: none;
  width: 30px;
}

.form--simple {
  & > *:not(:first-child) {
    margin-top: 15px;
  }
  & > label + *:not(:first-child) {
    margin-top: 0;
  }
}

input[type="checkbox"], input[type="radio"] {
  display: none;
}

input[type="checkbox"]:active + label:before, input[type="radio"]:active + label:before {
  transition-duration: 0s;
}

input[type="checkbox"] + label, input[type="radio"] + label {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  margin: 0 10px 12px 0;
  min-width: 100px;
  padding-left: 30px;
  position: relative;
}

input[type="checkbox"] + label:before, input[type="checkbox"] + label:after, input[type="radio"] + label:before, input[type="radio"] + label:after {
  box-sizing: content-box;
  content: '';
  display: block;
  left: 0;
  position: absolute;
}

input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  @include transition (background-color 0.2s);
  background-color: $form-element_bg;
  border-color: $form_border_color;
  border-style: solid;
  border-width: 1px;
  height: 20px;
  text-align: center;
  top: 1px;
  width: 20px;
}

input[type="checkbox"] + label:hover:before, input[type="radio"] + label:hover:before {
  background-color: $form-element_hover_bg;
}

input[type="checkbox"] + label:after, input[type="radio"] + label:after {
  @include transition (transform 0.2 ease-out);
  height: 10px;
  left: 6px;
  top: 7px;
  transform: scale(0);
  transform-origin: 50%;
  width: 10px;
}

input[type="radio"] + label:after {
  background-color: $form-element_symbol_color;
}

input[type="radio"]:checked + label:before {
  animation: borderscale 300ms ease-in;
  background-color: $form-element_bg;
  -moz-animation: borderscale 300ms ease-in;
  -webkit-animation: borderscale 300ms ease-in;
}

input[type="radio"]:checked + label:after {
  transform: scale(1);
}

input[type="radio"] + label:before, input[type="radio"] + label:after {
  border-radius: 50%;
}

input[type="checkbox"] + label:after {
  @include transform (rotate(-45deg) scale(0));
  @include transition (none);
  background-color: transparent;
  border-color: $form-element_symbol_color;
  border-style: solid;
  border-width: 0 0 2px 2px;
  height: 4px;
  left: 6px;
  top: 7px;
  width: 9px;
}

input[type="checkbox"]:checked + label:before {
  animation: borderscale .2s ease-in;
  -moz-animation: borderscale .2s ease-in;
  -webkit-animation: borderscale .2s ease-in;
}

input[type="checkbox"]:checked + label:after {
  @include transform (rotate(-45deg) scale(1));
  @include transition (transform .2s ease-out);
  content: '';
}

@-moz-keyframes borderscale {
  50% {
    box-shadow: 0 0 0 1px #999;
  }
}

@-webkit-keyframes borderscale {
  50% {
    box-shadow: 0 0 0 1px #999;
  }
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 1px #999;
  }
}

label {
  color: $form-label_color;
  font-size: 12px;
  font-weight: inherit;
  line-height: 20px;
  margin-bottom: 6px;
}

.required {
  color: $form-required_color;
  padding-right: 2px;
}

.input-group {
  .form-control-wrapper {
    flex: 1;
  }
  .errors {
    flex-basis: 100%;
    min-width: 100%;
    overflow: hidden;
  }
  .input-group-btn > .btn {
    border-radius: 0 !important;
  }
  .input-group-btn {
    width: 0;
  }
}

form .errors + .form-group, form .errors + .row {
  margin-top: 30px;
}

form .errors + .row {
  margin-top: 15px;
}

.aside-block .form-control + .errors {
  margin-bottom: 10px;
  margin-top: -5px;
}

.form-wrapper {
  &:not(:first-child) {
    margin-top: 30px;
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 15px;
    }
  }
  & > * {
    margin-bottom: 0;
  }
  & > *:not(:first-child) {
    margin-top: 18px;
  }
  & > * .form-group {
    margin: 18px 0 0;
  }
  .row {
    margin-top: 0;
  }
}

.form-wrapper > form {
  & > * {
    margin-bottom: 0;
  }
  & > *:not(:first-child) {
    margin-top: 18px;
  }
}

.form-inline--flex {
  display: flex;
  width: 100%;
  & > * {
    margin-left: 13px;
    margin-right: 13px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  & > *:first-child, input[type="hidden"] + * {
    margin-left: 0;
  }
  .form-control {
    flex: 1;
  }
  @media screen and (max-width: $screen-md-max) {
    button {
      min-width: 110px;
    }
  }
}

.btn--input-group {
  background: transparent;
  color: $input-group-btn_color;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  position: absolute;
  right: 0;
  &:before {
    border-color: $form_border_color;
    border-left-style: solid;
    border-left-width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
  &:hover:before {
    display: none;
  }
  [class*="icon-"] {
    font-size: 20px;
    line-height: 40px;
    margin-top: 0;
  }
}

.input-group-btn:last-child > .btn.btn--input-group:not(:hover), .input-group-btn:last-child > .btn.btn--input-group:focus:not(:hover) {
  background-color: $input-group-btn_bg;
  color: $input-group-btn_color;
}

.input-group-btn:last-child > .btn.btn--input-group {
  margin-left: 0;
  z-index: 4;
}

form .errors {
  color: $form-error_color;
  text-transform: inherit;
  -webkit-backface-visibility: hidden;
  ul {
    margin: 0;
    padding: 0;
  }
  ul > li {
    font-size: 13px !important;
    line-height: 16px !important;
    list-style: none;
    margin: 3px 0 0 !important;
    text-transform: inherit !important;
  }
}

.form--success {
  color: $form-success_color;
  font-size: 15px;
  font-weight: 600;
}

select option:not([disabled]) {
  color: $form_color;
}

select option:disabled {
  color: $form_color_disable;
}

.content-search {
  .btn {
    margin-left: -1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn [class*="icon-"] {
    font-size: 17px;
  }
}

select.bs-select-hidden, .bootstrap-select > select.bs-select-hidden, select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0
;
}

.bootstrap-select > .dropdown-toggle {
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: $form_color;
}

.bootstrap-select > select {
  border: none;
  bottom: 0;
  display: block !important;
  height: 100% !important;
  left: 50%;
  opacity: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0.5px !important;
}

.bootstrap-select > select.mobile-device {
  display: block !important;
  left: 0;
  top: 0;
  width: 100% !important;
  z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle, .bootstrap-select.is-invalid .dropdown-toggle, .was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: $form-error_border_color;
}

.bootstrap-select.is-valid .dropdown-toggle, .was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: $form-success_border_color;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

.bootstrap-select.form-control {
  border: none;
  margin-bottom: 0;
  padding: 0;
}

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  display: inline-block;
  float: none;
  margin-left: 0;
}

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right, .row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select, .form-horizontal .bootstrap-select, .form-group .bootstrap-select {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control, .form-group-sm .bootstrap-select.form-control {
  padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
  font-size: inherit;
  height: 100%;
  line-height: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle, .bootstrap-select.form-control-lg .dropdown-toggle {
  border-radius: inherit;
  font-size: inherit;
  line-height: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
  width: 100%;
}

.bootstrap-select.disabled, .bootstrap-select > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.disabled:focus, .bootstrap-select > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.bs-container {
  height: 0 !important;
  left: 0;
  padding: 0 !important;
  position: absolute;
  top: 0;
}

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block;
}

.bootstrap-select .dropdown-toggle .filter-option {
  height: 100%;
  left: 0;
  padding-bottom: inherit;
  padding-left: inherit;
  padding-top: inherit;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
}

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.bootstrap-select .dropdown-menu .text,
.dropdown-toggle .filter-option-inner-inner {
  b {
    font-weight: 400;
  }
}

.select-bold-price .bootstrap-select .dropdown-menu .text,
.select-bold-price .dropdown-toggle .filter-option-inner-inner {
  b {
    font-weight: 600;
    font-weight: 700;
  }
}

.bootstrap-select .dropdown-toggle .caret {
  margin-top: -2px;
  position: absolute;
  right: 12px;
  top: 50%;
  vertical-align: middle;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  box-sizing: border-box;
  min-width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
  float: none;
  margin: 0;
  padding: 0;
  position: static;
  -webkit-box-shadow: none !important;
}

.bootstrap-select .dropdown-menu li {
  position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.bootstrap-select .check-mark {
  display: none;
}

.bootstrap-select .btn, .bootstrap-select .btn:active, .bootstrap-select .btn:active:focus, .bootstrap-select .btn:visited, .bootstrap-select .btn:focus {
  background-color: $form_bg;
  border-color: $form_border_color;
  border-style: solid;
  border-width: 1px;
  color: $form_color;
  font-family: "Open Sans", sans-serif;
  line-height: 15px;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  background-color: transparent;
  border-color: $form_border_color;
  border-style: solid;
  border-width: 1px;
  color: $form_color;
}

.open > .dropdown-toggle.btn-default {
  background-color: transparent;
  border-color: $form_border_color;
  border-style: solid;
  border-width: 1px;
  color: $form_color;
}

.form-group .dropdown-menu {
  border-color: $form_border_color;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-shadow: none !important;
  outline: 0 !important;
  padding: 0;
  -webkit-box-shadow: none !important;
}

.form-group .dropdown-menu > li > a {
  font-size: 11px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.form-group .dropdown-menu > .active > a, .form-group .dropdown-menu > .active > a:focus, .form-group s.dropdown-menu > .active > a:hover {
  background-color: $dropdown-select-active_bg;
  color: $dropdown-select-active_color !important;
}

.bs-caret > span {
  display: none;
}

.bs-caret {
  background-color: $form_bg;
  border: 0;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  width: 35px;
}

.bs-caret:after {
  content: '\e921';
  display: block;
  font-family: 'icomoon';
  font-size: 11px;
  line-height: 35px;
  pointer-events: none;
  text-align: center;
  width: 100%;
}

.success-confirm,
.error-confirm {
  display: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.form-confirm + .form-group {
  margin-top: 0;
}

.success-confirm {
  color: $form-success_color;
}

.error-confirm {
  color: $form-error_color;
}

.has-error .form-control {
  border-color: $form-error_color;
  &,
  &:focus {
    box-shadow: none;
  }
}

.has-error .control-label,
.has-error .help-block,
.has-error .form-control-feedback {
  color: $form-error_color;
  font-size: 12px;
}