.prd-price {
  @include flexbox();
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 700;
  & > * {
    float: left;
    margin-right: 7px;
    white-space: nowrap;
  }
  & > *:last-child {
    margin-right: 0;
  }
  .price-new {
    font-weight: 600;
    color: $product-price_actual_color;
  }
  .price-old {
    font-weight: 300;
    color: $product-price_old_color;
    text-decoration: line-through;
  }
  .price-comment {
    margin-right: 10px;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    color: $product-price-comment_color;
  }
}

@media (max-width: $screen-md-max) {
  .prd-grid:not(.listing-view) .prd:not(.prd-horizontal-simple) {
    .prd-price {
      @include justify-content(center);
      background-color: $product-price-mobile_bg;
      font-size: 12px;
      line-height: 29px;
      .price-new {
        color: $product-price-mobile_actual_color;
      }
      .price-old {
        margin-right: 0;
        color: $product-price-mobile_old_color;
      }
      .price-comment {
        display: none;
      }
    }
  }
}