/* Magnific Popup CSS
------------------------------------- */
$grey: #888;

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    overflow: hidden;
    position: fixed;
    background: black;
    opacity: 0.8;
    filter: alpha(opacity = 80);
}

.mfp-wrap {
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    height: 100%;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 505;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    .mfp-image-holder .mfp-close {
        cursor: -moz-zoom-out;
        cursor: -webkit-zoom-out;
        cursor: zoom-out;
    }
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: lighten($grey, 14%);
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 504;
    a {
        color: lighten($grey, 27%)ccc;
        &:hover {
            color: $white;
        }
    }
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content, .mfp-s-loading .mfp-arrow {
    display: none;
}

button {
    &.mfp-close, &.mfp-arrow {
        overflow: visible;
        cursor: pointer;
        border: 0;
        -webkit-appearance: none;
        display: block;
        padding: 0;
        z-index: 506;
    }
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    right: 0px;
    top: 40px;
}

/* Close Button */

.mfp-close {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    cursor: pointer !important;
    z-index: 9999;
    background: lighten($grey, 22%);
    color: $white;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: darken($grey, 3%);
    }
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: lighten($grey, 14%);
    font-size: 13px;
    line-height: 18px;
}

.mfp-arrow {
    opacity: 0;
    margin: 0;
    top: 50%;
    margin-top: -24px;
    padding: 0;
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 9999;
    background: lighten($grey, 22%);
    color: $white;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: darken($grey, 3%);
    }
    &.mfp-arrow-left {
        content: "";
        font-family: "FontAwesome";
        font-size: 16px;
    }
    &.mfp-arrow-right {
        content: "";
        font-family: "FontAwesome";
        font-size: 16px;
        right: 0;
    }
    &.mfp-arrow-left:before {
        content: "";
        top: 1px;
        left: -1px;
        position: relative;
    }
    &.mfp-arrow-right:before {
        content: "";
        top: 1px;
        left: 1px;
        position: relative;
    }
}

.mfp-content:hover .mfp-arrow {
    opacity: 1;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
    .mfp-content {
        line-height: 0;
        width: 100%;
        max-width: 900px;
    }
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        background: black;
    }
}

.mfp-iframe-holder .mfp-close {
    top: 0px;
}

/* Popup */

#small-dialog {
    background: $white;
    padding: 30px 40px 35px 40px;
    text-align: left;
    max-width: 800px;
    margin: 40px auto;
    position: relative;
    h3 {
        margin-bottom: 20px;
    }
    span.line {
        padding-top: 7px;
    }
}

.my-mfp-zoom-in {
    .zoom-anim-dialog {
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
    }
    &.mfp-ready .zoom-anim-dialog {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    &.mfp-removing .zoom-anim-dialog {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;
    }
    &.mfp-bg {
        opacity: 0.001;
        /* Chrome opacity transition bug */
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
    }
    &.mfp-ready.mfp-bg {
        opacity: 0.8;
    }
    &.mfp-removing.mfp-bg {
        opacity: 0;
    }
}


/* animate in */

/* animate out */

/* Dark overlay, start state */

/* animate in */

/* animate out */

/* Main image in popup */

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */

.mfp-figure {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 40px;
        bottom: 40px;
        display: block;
        right: 0;
        width: auto;
        height: auto;
        z-index: -1;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
    line-height: 0;
}

.mfp-bottom-bar {
    margin-top: -30px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: lighten($grey, 42%);
    word-break: break-word;
    padding-right: 36px;
}

.mfp-figure small {
    color: lighten($grey, 21%);
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    .mfp-img-mobile {
        .mfp-image-holder {
            padding-left: 0;
            padding-right: 0;
        }
        img.mfp-img {
            padding: 0;
        }
        .mfp-figure:after {
            top: 0;
            bottom: 0;
        }
        .mfp-bottom-bar {
            background: rgba(0, 0, 0, 0.6);
            bottom: 0;
            margin: 0;
            top: auto;
            padding: 3px 5px;
            position: fixed;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &:empty {
                padding: 0;
            }
        }
        .mfp-counter {
            right: 5px;
            top: 3px;
        }
        .mfp-close {
            top: 0;
            right: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            background: rgba(0, 0, 0, 0.6);
            position: fixed;
            text-align: center;
            padding: 0;
        }
        .mfp-figure small {
            display: inline;
            margin-left: 5px;
        }
    }
    /* The shadow behind the image */
}

@media all and (max-width: 800px) {
    .mfp-arrow {
        width: 70px;
    }
}

.mfp-ie7 {
    .mfp-img {
        padding: 0;
    }
    .mfp-bottom-bar {
        width: 600px;
        left: 50%;
        margin-left: -300px;
        margin-top: 5px;
        padding-bottom: 5px;
    }
    .mfp-container {
        padding: 0;
    }
    .mfp-content {
        padding-top: 44px;
    }
    .mfp-close {
        top: 0;
        right: 0;
        padding-top: 0;
    }
}

.mfp-fade {
    &.mfp-bg {
        opacity: 0;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        &.mfp-ready {
            opacity: 0.85;
        }
        &.mfp-removing {
            opacity: 0;
        }
    }
    &.mfp-wrap {
        .mfp-content {
            opacity: 0;
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
        &.mfp-ready .mfp-content {
            opacity: 1;
        }
        &.mfp-removing .mfp-content {
            opacity: 0;
        }
    }
}