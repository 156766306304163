/* collection carousel */

.collection-carousel:not(.slick-initialized) {
  .collection-carousel-item {
    width: 20%;
    float: left;
    @media screen and (max-width: $screen-lg-max) {
      width: 25%;
    }
    @media screen and (max-width: $screen-md-max) {
      width: 33.333%;
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 50%;
    }
    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}

.collection-carousel > .slick-list {
  width: 100%;
}

.collection-carousel-item {
  position: relative;
  overflow: hidden;

  img {
    width: calc(100% + 1px);
    @include transform(scale(1.1) rotate(.001deg));
    @include transition (.5s);
  }

  & > a {
    display: block;
    position: relative;
    z-index: 1;
  }

  & > a:after {
    position: absolute;
    z-index: 0;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0;
    @include transition (.3s);
  }

  &:hover {
    img {
      @include transform(scale(1) rotate(.01deg));
    }

    & > a:after {
      opacity: .15;
    }
  }
}

.collection-carousel-title, .collection-carousel-title-lg {
  @include flexbox();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16% 12%;
  font-size: 24px;
  line-height: 1.636em;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: $heading_font_family;
  color: $collection-carousel-item-title_color;
  text-transform: uppercase;
  text-align: center;

  .btn-decor {
    color: $collection-carousel-item-title_color;

    &:after {
      background-color: $collection-carousel-item-title_color;
    }
  }

  &.flex-column {
    padding: 11% 10%;
  }
}

.collection-carousel-title {
  @media screen and (max-width: $screen-sm-max) {
    font-size: 18px;
    font-weight: 600;
  }
  @media screen and (max-width: $screen-xs-max) {
    font-size: 14px;
  }
}

.collection-carousel-item.collection-carousel-item--darktext .collection-carousel-title,
.collection-carousel-item.collection-carousel-item--darktext .collection-carousel-title-lg {
  color: #000;

  .btn-decor {
    color: #000;

    &:after {
      background-color: #000;
    }
  }
}

.collection-carousel-2 {
  text-align: center;
}

.collection-carousel-2-item {
  padding: 0 15px;

  .collection-carousel-2-item-inside {
    display: block;
    background-color: $collection-carousel2-item-caption_bg;
    padding: 0 0 40px;
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .btn {
    color: $collection-carousel2-item-button_color;
  }
}

.collection-carousel-2:not(.slick-initialized) {
  .collection-carousel-2-item {
    width: 20%;
    float: left;
    @media screen and (max-width: $screen-lg-max) {
      width: 25%;
    }
    @media screen and (max-width: $screen-md-max) {
      width: 33.333%;
    }
    @media screen and (max-width: $screen-sm-max) {
      width: 50%;
    }
    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}

.collection-carousel-2-title {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: $heading_font_family;
  text-transform: uppercase;
  color: $collection-carousel2-item-title_color;

  &:not(:first-child) {
    margin-top: 15px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .collection-carousel.slick-arrows-squared .slick-prev {
    left: 15px;
  }
  .collection-carousel.slick-arrows-squared .slick-next {
    right: 15px;
  }
}