.container {
  max-width: 1280px;
}

.login-register-or-section {
  margin-top: 36px;
}

.intl-tel-input {
  width: 100%;
  color: initial;
  input {
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid #e2e2e2;
    width: 100%;
  }
}

.img-footer-logo {
  width: 150px;
  height: 150px;
}

.whislist-icon-create {
  &:hover, &:focus, &:active {
    &:before {
      content: '';
    }
  }
}

.table-middle {
  td, th {
    vertical-align: middle !important;
  }
}

.order-view {
  margin: 30px 0 0 !important;
}


/* Responsive Tables */
.st-key {
  border: none;
  background-color: #f6f6f6;
  text-align: left;
  padding: 10px 20px;
  color: #1A1A1A;
  vertical-align: top;
  font-size: 14px;
  font-weight: 600;
  width: 40%;
  border: 1px solid #eeeeee; }

.st-val {
  width: 58%;
  text-align: left;
  padding: 10px 20px;
  border: 1px solid #f1f1f1;
  border-left: 0; }

.st-space {
  height: 25px; }

.st-val .cart-remove {
  color: #a2a2a2; }
.st-val .cart-remove:hover {
  color: #fff; }

.stacktable {
  width: 100%; }

.st-head-row.st-head-row-main {
  display: block; }

.stacktable.large-only {
  display: table; }

.stacktable.small-only {
  display: none; }

@media only screen and (max-width: 767px) {
  .stacktable.large-only {
    display: none;
  }
  .stacktable.small-only {
    display: table;
  }
}

//thank you page
#thank-you-page {
  .message {
    font-size: 14px;
  }
  .ok-icon {
    display: block;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 50px;
    text-transform: uppercase;
  }
  .thankyou-image {
    width: 200px;
    height: auto;
  }
  .guest-form {
    margin-top: 15px;
    max-width: 580px;
  }
  .box {
    &.box-full {
      border-bottom: 1px solid #ebebeb;
      border-top: 1px solid #ebebeb;
      margin-top: 30px;
      padding: 40px 0;
      width:100%;
    }
  }
}

//article
.article-content {
  p, ul, ol {
    margin-bottom: 20px;
  }
}

.bold {
  font-weight: 700;
}

.prd-pager {
  margin-top: 150px;
}

.tt-input.loading {
  background: none !important;
}

.collapse-offset {
  .collapse-content {
    position: relative;
    &.collapse-hidden {
      height: 240px;
      overflow-y: hidden;
      transition: 0.5s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: -webkit-linear-gradient(top,rgba(255,255,255,0) 33%,#fff 100%);
        background-image: linear-gradient(to bottom,rgba(255,255,255,0) 33%,#fff 100%);
        background-repeat: repeat-x;
        pointer-events: none;
        transition: 0.5s;
      }
    }
  }
  .collapse-read-more {
    margin-top: 25px;

  }
  margin-bottom: 15px;
}

.comingsoon {
  position: relative;
  background: url('/images/comingsoon.jpg')  center center / cover;;
  width: 100%;
  height: 100vh;
}

.search-holder, .nav-holder-s {
  .twitter-typeahead {
    background-color: $custom_color;
    padding: 5px 20px;
    border-radius: 3px;
    input {
      color: #fff !important;
      &::placeholder {
          color: #fff !important;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.03);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1.03);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.text-pulse {
  transform: scale(1);
  animation: pulse 3s infinite;
  &.custom-red {
    width: 90%;
    background-color: #c41a1a;
    text-align: center;
    color: #fff;
    padding-right: 0 !important;
    @media (max-width: 650px) {
      width: 82%;
    }
  }
}
.highlight-cd {
  margin: 45px 0 30px 0;
  transform: scale(1);
  animation: pulse 3s infinite;
  .highlight-countdown {
    background-color: rgba(0,112,37, 0.8); //black-weekend
    //background-color: rgba(29, 93, 169, 0.8);
    padding: 12px 25px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width: 450px) {
      font-size: 10px;
    }
    &.smaller {
      padding: 9.5px 25px;
    }
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      content: "\f017";
      font-size: 16px;
      left: 0;
      padding-right: 10px;
      @media (max-width: 450px) {
        font-size:14px;
      }
    }
  }
}

.product-listing {
  &.data-to-show-4 {
    .prd-img img {
      @media (min-width: $screen-md-max) {
        max-width: 210px;
      }
      @media (max-width: 1270px) and (min-width: $screen-md-max) {
        max-width: 190px;
      }
      @media (max-width: 1170px) and (min-width: $screen-md-max) {
        max-width: 170px;
      }
      @media (max-width: 1070px) and (min-width: $screen-md-max) {
        max-width: 155px;
      }
    }
  }
  &.data-to-show-3 {
    .prd-img img {
      @media (max-width: 1275px) and (min-width: 1180px) {
        max-width: 265px;
      }
      @media (max-width: 1180px) and (min-width: 1085px) {
        max-width: 240px;
      }
      @media (max-width: 1085px) and (min-width: $screen-lg-min) {
        max-width: 215px;
      }
    }
  }
  &.data-to-show-md-3 {
    .prd-img img {
      @media (max-width: 900px) and (min-width: $screen-md-min) {
      max-width: 265px;
      }
      @media (min-width: $screen-md-min) and (max-width: 835px) {
        max-width: 240px;
      }
    }
  }
  &.data-to-show-sm-2 {
    .prd-img img {
      @media (max-width: 625px) and (min-width: 555px) {
        max-width: 255px;
      }
      @media (max-width: 555px) and (min-width: $screen-sm-min) {
        max-width: 220px;
      }
    }
  }
}


#search-form-unique {
  margin-top: 17px;
  @media (max-width: 576px){
    .search-button {
      top: 71px;
      right: 26px;
      font-size: 24px;
    }
  }
  @include screen-lg-down {
    padding: 16px 40px;
    margin-top: 0;
  }
  .category-search-input {
    @media (max-width: 576px){
      margin-bottom: 5px;
    }
    select {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      background-color: $custom_color; //#E4E2E2 blackweekend
      height: 50px;
      white-space: normal;
      color: #fff;
      option {
        background-color: #fff;
      }
    }
    .search-form-wrapper {
      &:before {
        line-height: 50px !important;
      }
    }
  }
  .product-search-input, .category-search-input {
    width: 100%;
    .twitter-typeahead {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .product-search-input {
    padding-right: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.p-0-lg-down {
  @include screen-lg-down {
    padding: 0 !important;
  }
}

//social share
.product-social-share {
  span {
    padding-right: 25px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .social-share {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      a {
        padding: 15px;
        &.facebook {
          color: #4267B2;
          padding-left: 0;
        }
        &.viber {
          color: #7360f2;
        }
        &.whatsapp {
          color: #25D366;
        }
        &.linkedin {
          color: #2867b2;
        }
        &.email {
          padding-right: 0 !important;
        }
        i {
          font-size: 32px;
          color: $custom_color;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}

.is-available-label {
  margin-top: 0 !important;
  right: 50px !important;
  i {
    z-index: 3;
    font-size: 13px;
  }
  &.simple-product {

  }
  &.available {
    &:after {
      background-color: #77b900;
      //background-color: $text_color_light; //black-weekend
    }
  }
  &.not-available {
    &:after {
      background-color: #efa300;
      //background-color: $text_color_light; //black-weekend
    }
  }
}

.prd-vertical-sm {
  &:hover, &:focus, &:active {
    .is-available-label {
      &.top-product {
        opacity: 1;
      }
    }
  }
  .is-available-label {
    transition: .25s ease-in-out;
    &.top-product {
      z-index: 5;
      width: 29px;
      height: 29px;
      position: absolute;
      left: 0;
      text-align: center;
      line-height: 29px;
      color: #fff;
      background-color: #007025; //$lightBlue - blackweekend
      border-radius: 50%;
      @include screen-md-up {
        opacity: 0;
      }
    }
    &.available {
        background-color: #77b900 !important;
      &:after {
        background-color: #77b900 !important;
        //background-color: $text_color_light; //black-weekend
      }
    }
    &.not-available {
        background-color: #efa300 !important;
      &:after {
        background-color: #efa300 !important;
        //background-color: $text_color_light; //black-weekend
      }
    }
  }
}

.highlight-holder {
  position: relative;
  .highlight-border {
    border: 2px solid #f2a104;
    position: absolute;
    z-index: -1;
  }
}

.bg-adult {
  opacity: 1 !important;
}

.adult-div-container {
  padding-bottom: 30px;
  .adult-header {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: $custom-color;
    margin-bottom: 30px;
    .close-icon {
      color: #fff;
      position: absolute;
      top: 13px;
      right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }

}

.btn {
  &.valentine-day {
    &:hover, &:focus, &:active {
      background-color: #E566D0;
    }
  }
}

.discount {
  font-size: 18px;
  height: 40px;
  width: 60px;
  color: $white;
  padding-top: 7px;
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  background: $alert-danger_bg;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 151;
}

.discount-sm {
  margin-top: 35px;
  height: 25px;
  width: 40px;
  color: $white;
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  background: $alert-danger_bg;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 151;
}
