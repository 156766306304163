.promo-topline {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $promo-topline_text_color;
  a, a:focus {
    color: $promo-topline-link_color;
  }
  a:hover {
    color: $promo-topline-link-hover_color;
  }
  @media (max-width: $screen-sm-max) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    b {
      font-weight: 600;
    }
  }
  @media (max-width: $screen-xs-max) {
    font-size: 11px;
    line-height: 16px;
  }
  @media screen and (max-width: ($mobile-menu-breikpoint - 1)) {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #fff;
      opacity: .5;
    }
  }
}

.promo-topline-item {
  position: relative;
  padding: 0 55px;
  @media (max-width: $screen-sm-max) {
    padding: 0 18px;
  }
  &:not(:last-child):after {
    content: '|';
    position: relative;
    top: -1px;
    padding: 0 10px;
    font-weight: bold;
  }
}

.promo-topline .promo-topline-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  @include flexbox();
  @include align-items(center);
  padding: 0 20px;
  font-size: 18px;
  text-decoration: none;
  @include transition(opacity .2s);
  -webkit-backface-visibility: hidden;
  [class*='icon'] {
    color: $promo-topline-close_color;
  }
  &:hover {
    background-color: rgba(255,255,255,.65);
    [class*='icon'] {
      color: $custom_color;
    }
  }
  @media (max-width: $screen-sm-max) {
    padding: 0 10px;
  }
}