/* instagram */

.instagram-carousel {
  position: relative;
  margin: 30px 0;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: $screen-xs-max) {
    margin: 15px 0;
  }
}

.instagram-feed-full {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  width: calc(100% + 10px);
  overflow: hidden;
  white-space: nowrap;

  a {
    display: block;
    position: relative;
    float: left;
    padding: 0 5px;
    width: 16.667%;

    &:before {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 40px;
      margin: -20px 0 0 -20px;
      content: "\e955";
      font-family: 'icomoon';
      font-size: 40px;
      line-height: 1em;
      color: $instagram-icon_color;
      text-align: center;
      opacity: 0;
      @include transform (scale(0.5));
      @include transition (.3s);
      -webkit-backface-visibility: hidden;
    }

    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      top: 0;
      bottom: 0;
      left: 5px;
      right: 5px;
      background: $instagram-hover_bg;
      opacity: 0;
      @include transition (.3s);
    }

    &:hover:before {
      opacity: 1;
      @include transform (scale(1));
    }

    &:hover:after {
      opacity: .35;
    }

    span {
      display: block;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
    }

    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.instagram-carousel-arrows {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  @include transition (opacity 0.2s);
  @media screen and (max-width: $screen-sm-max) {
    left: 0;
    width: 100%;
    transform: translateX(0);
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 1;
    display: block;
    height: 47px;
    width: 47px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: $slick-arrow_bg;
    top: 50%;
    padding: 0;
    margin-top: -23px;
    opacity: 1;
    transform: none;
    pointer-events: auto;
    @include transition (0.2s);
    @media screen and (max-width: $screen-xs-max) {
      height: 44px;
      width: 44px;
    }

    &:hover {
      background: $slick-arrow-hover_bg;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    display: inline-block;
    font-size: 25px;
    line-height: 49px;
    color: $slick-arrow_color-dark;
    opacity: 1;
    @media screen and (max-width: $screen-xs-max) {
      font-size: 24px;
      line-height: 44px;
    }
  }

  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: $slick-arrow_color-dark;
    opacity: 1;
  }

  .slick-prev {
    left: 0;
    right: auto;
  }

  .slick-next {
    right: 0;
    left: auto;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .instagram-carousel-arrows {
    .slick-prev {
      opacity: 0;
      left: -15px;
      right: auto;
    }

    .slick-next {
      opacity: 0;
      right: -15px;
      left: auto;
    }
  }
  .instagram-carousel:hover .instagram-carousel-arrows {
    .slick-prev {
      opacity: 1;
      left: 0;
      right: auto;
    }

    .slick-next {
      opacity: 1;
      right: 0;
      left: auto;
    }
  }
}

.instagram-grid {
  overflow: hidden;
  margin-top: -10px;

  a {
    display: block;
    float: left;
    margin-top: 10px;
    width: 16.666%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);

    &:hover span:after {
      opacity: 0.25;
    }

    span {
      display: block;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $instagram-grid-hover_bg;
        opacity: 0;
        @include transition(opacity .2s);
      }
    }

    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.instagram-grid.three-in-row a {
  width: 33.333%;
}

.instagram-grid.eleven-in-row a {
  width: 9.091%;
  @media screen and (max-width: $screen-md-max) {
    width: 12.5%;
  }
  @media screen and (max-width: $screen-sm-max) {
    width: 20%;
  }
  @media screen and (max-width: $screen-xs-max) {
    width: 33.333%;
    &:nth-child(12) ~ a {
      display: none;
    }
  }
}

.instagram-feed-four {
  @include flexbox();
  a {
    display: block;
    position: relative;
    min-width: 33.333%;
    @media screen and (max-width: $screen-sm-max) {
      min-width: 50%;
    }
    @media screen and (max-width: $screen-xs-max) {
      min-width: 65%;
    }

    &:before {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 40px;
      margin: -20px 0 0 -20px;
      content: "\e955";
      font-family: 'icomoon';
      font-size: 40px;
      line-height: 1em;
      color: $instagram-icon_color;
      text-align: center;
      opacity: 0;
      @include transform (scale(0.5));
      @include transition (.3s);
      -webkit-backface-visibility: hidden;
    }

    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $instagram-hover_bg;
      opacity: 0;
      @include transition (.3s);
    }

    &:hover:before {
      opacity: 1;
      @include transform (scale(1));
    }

    &:hover:after {
      opacity: .35;
    }

    span {
      display: block;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
    }

    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.instagram-feed-text {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  height: 100%;
  padding: 10px;
  background-color: $instagram-feed-text_bg;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 600;
  font-family: $heading_font_family;
  color: $instagram-feed-text_color;
  text-align: center;
  text-decoration: none;

  span {
    font-size: 2em;
    line-height: 1em;
    margin-bottom: 15px;
  }

  &:hover {
    text-decoration: none;
  }

  .btn-decor {
    color: $instagram-feed-text_color;
    &:after {
      background: $instagram-feed-text_color;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    font-size: 20px;
  }
  @media screen and (max-width: $screen-sm-max) {
    font-size: 18px;
  }
  @media screen and (max-width: $screen-xs-max) {
    font-size: 16px;
    span {
      font-size: 1.5em;
      margin-bottom: 7px;
    }
    .btn-decor {
      font-size: 13px;
    }
  }
}

.instagram-second-row {
  direction: rtl;
}