
section.linking {
    border-bottom: 0;
    padding-top: 28px;
}

#quantity {
    position: relative;
    float: left;
}

.qty {
    width: 45px;
    height: 40px;
    text-align: center;
    float: left;
    padding: 8px 9px;
    @include screen-sm-down{
        width: 100px;
    }
}

input.qty {
    padding: 8px 9px;
}

.minicart-backdrop {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    max-height: 100vh !important;
    z-index: 159;
    background-color: rgba(0,0,0, 0.6);
    transition: opacity 0.2s ease-out;
    display: none;
    &.opened {
        display: block;
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }
}
.cart-clear-coupon{
    cursor: pointer;
    &:hover{
        color: $alert-danger_bg;
    }
}