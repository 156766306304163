.highlight-slick {
  .prd-img-area {
    .prd-img {
      img {
        width: 290px !important;
      }
    }
  }
}

.highlight-content {
  .more-link-wrapper {
    display: none;
    @media (min-width: 1000px) {
      display: block;
      position: absolute;
      right: 65px;
    }
  }
  .more-link-wrapper-bottom {
    display: none;
    @media (max-width: 999px) {
      display: block;
      margin-bottom: 25px;
      margin-top: -10px;
    }
    @media (max-width: 479px) {
      margin-top: 0;
    }
  }
}