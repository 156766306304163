.color-red {
  background-color: red;
}

.color-white {
  background-color: white;
}

.color-blue {
  background-color: blue;
}

.color-orange {
  background-color: orange;
}

.color-black {
  background-color: black;
}

.color-green {
  background-color: green;
}

.color-purple {
  background-color: purple;
}