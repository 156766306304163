.card-imgtext-wrap {
  max-width: 1406px;
  margin-left: auto;
  margin-right: auto;
  .row {
    margin-left: -50px;
    margin-right: -50px;
    @media screen and (min-width: $screen-lg-min) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media screen and (max-width: $screen-sm-max) {
      margin-left: -15px;
      margin-right: -15px;
    }
    & > [class*='col'] {
      padding-left: 50px;
      padding-right: 50px;
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .col {
      @include flexWidth(39.5%);
      @media screen and (max-width: $screen-sm-max) {
        @include flexWidth(100%);
      }
    }
    .col-double {
      @include flexWidth(60.5%);
      @media screen and (max-width: $screen-sm-max) {
        @include flexWidth(100%);
      }
    }
    &:not(.flex-row-reverse) > *:last-child {
      text-align: right;
    }
    &.flex-row-reverse > *:first-child {
      text-align: right;
    }
  }
}

.card-imgtext-image {
  &:not(:first-child) {
    margin-top: 50px;
    @media screen and (max-width: $screen-md-max) {
      margin-top: 30px;
    }
  }
  img {
    display: inline-block;
  }
}

.card-imgtext-text {
  padding: 0 63px;
  @media (max-width: $screen-lg-max) {
    padding: 0 30px;
  }
  @media (max-width: $screen-md-max) {
    padding: 0 20px;
  }
  &:not(:first-child) {
    margin-top: 50px;
    @media (max-width: $screen-md-max) {
      margin-top: 30px;
    }
  }
  & > * {
    margin-bottom: 0;
  }
  & > *:not(:first-child) {
    margin-top: 25px;
    @media (max-width: $screen-sm-max) {
      margin-top: 15px;
    }
  }
}

.card-imgtext-title {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 800;
  @media (max-width: $screen-sm-max) {
    font-size: 18px;
  }
}

.aside .card-imgtext-wrap {
  .row {
    margin-left: -15px;
    margin-right: -15px;
    @media screen and (min-width: $screen-lg-min) {
      padding-left: 0;
      padding-right: 0;
    }
    & > [class*='col'] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.aside .card-imgtext-text {
  padding: 0 15px;
  @media (max-width: $screen-md-max) {
    padding: 0;
  }
}