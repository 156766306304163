.fancy-box-content {
  @include screen-min-1200 {
    width: 50%;
  }
}
.fancy-spinner {
  left: 50%;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  z-index: 99999;
  margin: -30px 0 0 -30px;
}

.fancy-border-red {
  border: 5px solid $modal-info-error_color;
}

#block_layer {
  background: transparent;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 900000;

  top: 0;
  left: 0;
}